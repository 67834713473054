<!-- 催单弹窗组件 -->
<template>
  <el-dialog
      width="40%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'催单':'催单'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        label-width="100px">
      <el-form-item label="催促对象：" style="margin-bottom: 0!important;">
        <el-radio v-model="radio" label="1">服务商</el-radio>
        <el-radio v-model="radio" label="2">司机</el-radio>
      </el-form-item>
      <div style="margin-top: 10px;margin-bottom: 10px;">
        <el-input
            clearable
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 5}"
            placeholder="请输入其他原因"
            v-model="details">
        </el-input>
      </div>
      <el-form-item label="快速输入：">
        <span class="quickInput" v-for="(item,index) in quickInputList" :key="index" @click="change(item,index)">
          {{item.name}}
        </span>
      </el-form-item>
      <el-form-item label="其他催促方式">
        <div class="kuai" v-for="(item,index) in fangshiList" :key="index" :class="{kuai1:index==isFangshi}" @click="activeItem(item,index)">
          <img :src="item.img" alt="" style="width: 49px;height: 49px;margin-top: 15px;">
          <div style="font-size: 12px;font-weight: 500;color: #868792;">{{item.shengyu}}</div>
          <div style="font-size: 16px;font-weight: 500;color: #2d2f47;">{{item.title}}</div>
          <img v-if="isFangshi == index" src="@/assets/images/home/order/biaozhi.png" alt="" style="width: 26px;height: 26px;position: absolute;bottom: 0;right: 0">
        </div>
      </el-form-item>
    </el-form>


    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>

import {order_reminder} from '@/api/orderModule'
// 引入的接口
import { firm_wallet} from "@/api/corporateWallet";

export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,


      radio:'1',
      identity:'',
      object_id:'',

      quickInputList:[
        {
          name:'客户等待时间较长，请尽快救援'
        },
        {
          name:'尽快联系客户'
        }
      ],

      isFangshi:0,
      fangshiList:[
        // {
        //   img:require('../../../../assets/images/home/order/fangshi1active.png'),
        //   shengyu:'余200分钟',
        //   title:'拨打电话'
        // },
        {
          img:require('../../../../assets/images/home/order/fangshi2active.png'),
          shengyu:'',
          title:'发送短信',
          reminder_type:'sms'
        },
        // {
        //   img:require('../../../../assets/images/home/order/fangshi3active.png'),
        //   shengyu:'余20条',
        //   title:'自动语音电话',
        //   reminder_type:'voice'
        // },
      ],

      details:'',

      //企业钱包基本信息
      firm_wallet:{},

    };
  },

  mounted() {
    if (this.data) {
      console.log(this.data)
      console.log('催单')
      this.form = Object.assign({}, this.data);
      this.isUpdate = true;
    } else {
      this.form = {};
      this.isUpdate = false;
    }

    // 催促对象
    if(this.radio == '1'){
      this.identity = 'facilitator'
      this.object_id = this.data.facilitator_id
    }else {
      this.identity = 'driver'
      this.object_id = this.data.driver_id
    }

    this.getfirm_wallet();
  },

  methods: {
    //获取企业钱包基本信息接口
    getfirm_wallet(){
      firm_wallet().then(res => {
        this.firm_wallet = res.data;
        this.fangshiList[0].shengyu = '余' + this.firm_wallet.note_surplus + '条'
      })
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if(this.firm_wallet.note_surplus == 0){
            this.$message.error('余额不足，请充值后再试')
          }else {
            // 催促对象
            if(this.radio == '1'){
              this.identity = 'facilitator'
              this.object_id = this.data.facilitator_id
            }else {
              this.identity = 'driver'
              this.object_id = this.data.driver_id
            }
            let data = {
              odd: this.data.order_id,
              uid: this.data.uid,
              object_id: this.object_id,
              identity: this.identity,
              details: this.details,
              reminder_type: this.fangshiList[this.isFangshi].reminder_type
            }
            order_reminder(this.data.id,data).then(res => {
              console.log(res)
              if (res.code == 200) {
                this.loading = true;
                this.updateVisible(false);
                this.$emit('done');
                this.loading = false;
                this.details = '';
                this.$message.success(res.msg);
              }else {
                this.$message.error(res.msg);
              }
            })
          }
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //选择方式
    activeItem(item,index){
      this.isFangshi = index;
      // if(item.title == '拨打电话'){
      //   this.fangshiList[0].img = require('../../../../assets/images/home/order/fangshi1active.png');
      //   this.fangshiList[1].img = require('../../../../assets/images/home/order/fangshi2.png');
      //   this.fangshiList[2].img = require('../../../../assets/images/home/order/fangshi3.png');
      // }else if(item.title == '发送短信'){
      //   this.fangshiList[0].img = require('../../../../assets/images/home/order/fangshi1.png');
      //   this.fangshiList[1].img = require('../../../../assets/images/home/order/fangshi2active.png');
      //   this.fangshiList[2].img = require('../../../../assets/images/home/order/fangshi3.png');
      // }else if(item.title == '自动语音电话'){
      //   this.fangshiList[0].img = require('../../../../assets/images/home/order/fangshi1.png');
      //   this.fangshiList[1].img = require('../../../../assets/images/home/order/fangshi2.png');
      //   this.fangshiList[2].img = require('../../../../assets/images/home/order/fangshi3active.png');
      // }
      // if(item.title == '发送短信'){
      //   // this.fangshiList[0].img = require('../../../../assets/images/home/order/fangshi1.png');
      //   this.fangshiList[0].img = require('../../../../assets/images/home/order/fangshi2active.png');
      //   this.fangshiList[1].img = require('../../../../assets/images/home/order/fangshi3.png');
      // }else if(item.title == '自动语音电话'){
      //   // this.fangshiList[0].img = require('../../../../assets/images/home/order/fangshi1.png');
      //   this.fangshiList[0].img = require('../../../../assets/images/home/order/fangshi2.png');
      //   this.fangshiList[1].img = require('../../../../assets/images/home/order/fangshi3active.png');
      // }
    },

    //选择快速输入
    change(item,index){
      console.log(item)
      this.details += item.name;
    },



  }
}
</script>

<style scoped lang="scss">
  .quickInput{
    text-align: center;
    background: #eff2ff;
    border-radius: 20px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
    font-weight: 400;
    color: #5976e1;
    display: inline-block;
    cursor: pointer;
  }

  .kuai{
    background: #ffffff;
    border: 1px solid #ededf5;
    border-radius: 6px;
    //padding: 20px 30px 20px 30px;
    width: 140px;
    margin-right: 20px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    position: relative;
  }
  .kuai:hover{
    background: #FFFAF2;
    border-color: #FAD08D;
  }
  .kuai1{
    background: #FFFAF2;
    border-color: #FAD08D;
  }
</style>

<template>
  <div>
    <div v-if="showEdit === false">
      <div class="title" style="margin-bottom: 0;">
        <div class="title_t">
          <span>订单详情</span>
        </div>
        <div class="title_r" @click="returnPage">
          <span>返回</span>
        </div>
      </div>

      <!--选项-->
      <div class="xuanxiang">
        <div class="xuanxiang_k" v-for="(item,index) in activeList" :key="index" :class="{xuanxiang_k1:index==isShow}" @click="xuanxiangclick(item,index)">
          <img :src="item.img" alt="">
          <span>{{item.name}}</span>
        </div>
      </div>

      <!--基本信息 模块-->
      <div v-if="isShow == 0">
        <el-form ref="form" :model="form" label-width="100px">
          <!-- 基本信息选项 -->
          <el-card shadow="never">
            <!--<el-tabs type="border-card">-->
            <!--  <el-tab-pane label="注意事项"></el-tab-pane>-->
            <!--  <el-tab-pane label="基本信息"></el-tab-pane>-->
            <!--  <el-tab-pane label="接单信息"></el-tab-pane>-->
            <!--  <el-tab-pane label="结算信息"></el-tab-pane>-->
            <!--  <el-tab-pane label="考核信息"></el-tab-pane>-->
            <!--  <el-tab-pane label="公里信息"></el-tab-pane>-->
            <!--  <el-tab-pane label="回访信息"></el-tab-pane>-->
            <!--  <el-tab-pane label="通话信息"></el-tab-pane>-->
            <!--  <el-tab-pane label="照片审核"></el-tab-pane>-->
            <!--  <el-tab-pane label="派单时间轴"></el-tab-pane>-->
            <!--  <el-tab-pane label="工单照片"></el-tab-pane>-->
            <!--  <el-tab-pane label="订单备注"></el-tab-pane>-->
            <!--  <el-tab-pane label="司机报备"></el-tab-pane>-->
            <!--  <el-tab-pane label="关联订单"></el-tab-pane>-->
            <!--  <el-tab-pane label="跟进记录"></el-tab-pane>-->
            <!--  <el-tab-pane label="短信电话"></el-tab-pane>-->
            <!--  <el-tab-pane label="客户评价"></el-tab-pane>-->
            <!--  <el-tab-pane label="催单"></el-tab-pane>-->
            <!--  <el-tab-pane label="修改记录"></el-tab-pane>-->
            <!--</el-tabs>-->


            <el-row>
              <!--第一行-->
              <el-col :span="24" style="margin-top: 10px;">
                <div class="info">
                  <div class="info_active" @click="goAnchor('#zhuyishixiang')">
                    <img src="@/assets/images/home/order/icon1.png" alt="">
                    <div class="text">注意事项</div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#jibenxinxi')">
                    <img src="@/assets/images/home/order/icon2.png" alt="">
                    <div class="text">基本信息</div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#jiedanxinxi')">
                    <img src="@/assets/images/home/order/icon3.png" alt="">
                    <div class="text">受理信息</div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#jiesuanxinxi')">
                    <img src="@/assets/images/home/order/icon4.png" alt="">
                    <div class="text">结算信息</div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#kaohexinxi')">
                    <img src="@/assets/images/home/order/icon5.png" alt="">
                    <div class="text">考核信息</div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#gonglixinxi')">
                    <img src="@/assets/images/home/order/icon6.png" alt="">
                    <div class="text">公里信息</div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#huifangxinxi')">
                    <img src="@/assets/images/home/order/icon7.png" alt="">
                    <div class="text">回访信息 </div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#tonghuaxinxi')">
                    <img src="@/assets/images/home/order/icon8.png" alt="">
                    <div class="text">通话信息</div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#zhaopianshenhe')">
                    <img src="@/assets/images/home/order/icon9.png" alt="">
                    <div class="text">照片审核 </div>
                  </div>
                </div>
                <div class="info" style="width: 10% !important;">
                  <div class="info_active" @click="goAnchor('#pandanshijianzhou')">
                    <img src="@/assets/images/home/order/icon10.png" alt="">
                    <div class="text">派单时间轴</div>
                  </div>
                </div>

              </el-col>
              <!--第二行-->
              <el-col :span="24" style="margin-top: 20px;">
                <div class="info">
                  <div class="info_active" @click="goAnchor('#gongdanzhaopian')">
                    <img src="@/assets/images/home/order/icon11.png" alt="">
                    <div class="text">工单照片</div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#dingdanbeizhu')">
                    <img src="@/assets/images/home/order/icon12.png" alt="">
                    <div class="text">订单备注</div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#jishibaobei')">
                    <img src="@/assets/images/home/order/icon13.png" alt="">
                    <div class="text">司机报备</div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#guanliandingdan')">
                    <img src="@/assets/images/home/order/icon14.png" alt="">
                    <div class="text">关联订单</div>
                  </div>
                </div>
                <!--<div class="info">-->
                <!--  <div class="info_active" @click="goAnchor('#zidingdan')">-->
                <!--    <img src="@/assets/images/home/order/icon15.png" alt="">-->
                <!--    <div class="text">子订单</div>-->
                <!--  </div>-->
                <!--</div>-->
                <div class="info">
                  <div class="info_active" @click="goAnchor('#genjinjilu')">
                    <img src="@/assets/images/home/order/icon16.png" alt="">
                    <div class="text">跟进记录</div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#duanxindianhu')">
                    <img src="@/assets/images/home/order/icon17.png" alt="">
                    <div class="text">短信电话</div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#chezhupingjia')">
                    <img src="@/assets/images/home/order/icon18.png" alt="">
                    <div class="text">客户评价</div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#tousu')">
                    <img src="@/assets/images/home/order/icon15.png" alt="">
                    <div class="text">投诉记录</div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#cuidan')">
                    <img src="@/assets/images/home/order/icon19.png" alt="">
                    <div class="text">催单记录</div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#xiugaijilu')">
                    <img src="@/assets/images/home/order/icon20.png" alt="">
                    <div class="text">修改记录</div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-card>

          <el-card shadow="never">
          <!--<div style="margin-top: 0px;margin-bottom: 20px;">-->
              <!--功能选项按钮-->
              <el-row :gutter="15" style="    display: flex;align-items: center;">
                <!--左侧-->
                <el-col :span="14">
                  <div class="FunctionOptions" @click="ToExamine()" v-if="form.order_status == 6 && form.check_status == 0" v-auths="[`${$config.uniquePrefix}orderModule:info:audit`]">
                    <img src="@/assets/images/home/order/active2.png" alt="">
                    <span>审核</span>
                  </div>
                  <!--<div class="FunctionOptions" @click="Reporting()">-->
                  <!--  <img src="@/assets/images/home/order/active2.png" alt="">-->
                  <!--  <span>报备</span>-->
                  <!--</div>-->
                  <div class="FunctionOptions" @click="ReturnVisit()" v-auths="[`${$config.uniquePrefix}orderModule:info:returnvisit`]">
                    <img src="@/assets/images/home/order/active3.png" alt="">
                    <span>回访</span>
                  </div>
                  <div class="FunctionOptions" @click="ToReviewACase()" v-if="form.order_status == 6 && form.check_status == 3" v-auths="[`${$config.uniquePrefix}orderModule:info:returnaudit`]">
                    <img src="@/assets/images/home/order/active4.png" alt="">
                    <span>复审</span>
                  </div>
                  <div class="FunctionOptions" @click="Reassignment()" v-if="form.order_status == 2 || form.order_status == 3 || form.order_status == 4 || form.order_status == 5" v-auths="[`${$config.uniquePrefix}orderModule:info:reassignment`]">
                    <img src="@/assets/images/home/order/active5.png" alt="">
                    <span>改派</span>
                  </div>
                  <!--<div class="FunctionOptions" @click="biaoji()">-->
                  <!--  <img src="@/assets/images/home/order/active6.png" alt="">-->
                  <!--  <span>标记</span>-->
                  <!--</div>-->
                  <!--<div class="FunctionOptions" @click="TakeBack()">-->
                  <!--  <img src="@/assets/images/home/order/active7.png" alt="">-->
                  <!--  <span>收回</span>-->
                  <!--</div>-->
                  <div class="FunctionOptions" @click="opencancel()" v-if="form.order_status != 6" v-auths="[`${$config.uniquePrefix}orderModule:info:cancel`]">
                    <img src="@/assets/images/home/order/active8.png" alt="">
                    <span>取消</span>
                  </div>
                  <div class="FunctionOptions" @click="openempty()" v-if="form.order_status != 6" v-auths="[`${$config.uniquePrefix}orderModule:info:empty`]">
                    <img src="@/assets/images/home/order/active9.png" alt="">
                    <span>空驶</span>
                  </div>
                  <div class="FunctionOptions" @click="Reassignment()">
                    <img src="@/assets/images/home/order/active9.png" alt="">
                    <span>附近修理厂</span>
                  </div>
                  <!--<div class="FunctionOptions" @click="qiangdan()">-->
                  <!--  <img src="@/assets/images/home/order/active6.png" alt="">-->
                  <!--  <span>抢单</span>-->
                  <!--</div>-->
                </el-col>

                <!--右侧-->
                <el-col :span="10" style="display: flex;">
                  <div style="cursor: pointer;width: 150px;" class="copy" @click="onCopy">
                    <img style="width: 15px;height: 15px;vertical-align: middle" src="@/assets/images/home/order/lianjie.png" alt="">
                    <span style="font-size: 14px;font-weight: 400;text-align: left;color: #fea837;vertical-align: middle; margin-left: 5px;">复制用户H5链接</span>
                  </div>
                  <div style="cursor: pointer;margin-left: 10px;width: 150px;" class="copy" @click="onCopy1">
                    <img style="width: 15px;height: 15px;vertical-align: middle" src="@/assets/images/home/order/lianjie.png" alt="">
                    <span style="font-size: 14px;font-weight: 400;text-align: left;color: #fea837;vertical-align: middle; margin-left: 5px;">复制外协链接</span>
                  </div>
                  <div style="margin-left: 20px; cursor: pointer;width: 150px;" @click="CustomerScript()">
                    <img style="width: 15px;height: 15px;vertical-align: middle" src="@/assets/images/home/order/huashu.png" alt="">
                    <span style="font-size: 14px;font-weight: 400;text-align: left;color: #5976E1;vertical-align: middle; margin-left: 5px;">{{form.joinInfo.items}}救援话术</span>
                  </div>
                </el-col>
              </el-row>
              <!-- 消息 -->
              <!--<el-row :gutter="15" style="margin-top: 20px;">-->
              <!--  <el-col :span="24">-->
              <!--    <div class="xiaoxi">-->
              <!--      <img src="@/assets/images/home/order/xiaoxi.png" alt="">-->
              <!--      <span style="margin-left: 20px;">2023-09-0800:00:12</span>-->
              <!--      <span style="margin-left: 20px;">到达超时</span>-->
              <!--      <span style="margin: 20px;">责任人：张三三</span>-->
              <!--      <span style="color: #34DC73;">待处理</span>-->
              <!--    </div>-->
              <!--  </el-col>-->
              <!--</el-row>-->
                <!--  注意事项-->
                <div class="ageing" style="margin-top: 30px;" id="zhuyishixiang">
                  <div class="Datalist">
                    <div class="Datalist_l">
                      <div class="Datalist_icon"></div>
                      <div class="Datalist_text">
                        注意事项
                      </div>
                    </div>
                  </div>
                  <!-- H5链接说明文字 -->
                  <div style="margin-top: 15px;">
                    <el-input
                        clearable
                        :disabled="true"
                        :autosize="{ minRows: 2, maxRows: 5}"
                        type="textarea"
                        :rows="2"
                        placeholder="请输入注意事项"
                        v-model="form.remark">
                    </el-input>
                  </div>
                </div>
                <!--基本信息-->
                <div class="ageing" style="margin-top: 20px;" id="jibenxinxi">
                  <div class="Datalist">
                    <div class="Datalist_l">
                      <div class="Datalist_icon"></div>
                      <div class="Datalist_text">
                        基本信息
                      </div>
                      <div style="margin-left: 20px;" v-if="isEdit == true">
                        <el-radio v-model="form.is_appointment" :label="0">立即救援</el-radio>
                        <el-radio v-model="form.is_appointment" :label="1">预约救援</el-radio>
                      </div>
                    </div>
                    <div>
                      <div class="custom" style="margin-left: 20px">
                        <el-button class="custom-button" v-if="isEdit == false" @click="Edit()" v-auths="[`${$config.uniquePrefix}orderModule:info:infoedit`]">
                          <!--el-icon-devops 是自己命名的-->
                          <i class="el-icon-bianji" />
                          <span style="vertical-align: middle">编辑</span>
                        </el-button>
                        <div v-else>
                          <el-button class="custom-button" v-auths="[`${$config.uniquePrefix}orderModule:info:infoedit`]">
                            <span style="vertical-align: middle" @click="infoConfirm()">确认</span>
                          </el-button>
                          <el-button @click="infoCancel()">取消</el-button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="ageing_b">
                    <el-row style="margin: 15px;">
                      <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                        <span class="ageing_b_text_l">订单编号：</span>
                        <span class="ageing_b_text_r">{{data.order_id}}</span>
                      </el-col>
                      <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                        <span class="ageing_b_text_l">保单号：</span>
                        <span class="ageing_b_text_r">{{form.settlementInformation.policy_number}}</span>
                      </el-col>
                      <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                        <span class="ageing_b_text_l">案件号：</span>
                        <span class="ageing_b_text_r">{{form.settlementInformation.case_number}}</span>
                      </el-col>
                      <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                        <span class="ageing_b_text_l">事故号：</span>
                        <el-tooltip class="item" effect="dark" :content="form.settlementInformation.nickname" placement="top">
                          <span class="ageing_b_text_r">
                            {{}}
                          </span>
                        </el-tooltip>
                      </el-col>
                    </el-row>
                    <el-row style="margin: 15px;">
                      <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                        <span class="ageing_b_text_l">救援类型：</span>
                        <span class="ageing_b_text_r">{{}}</span>
                      </el-col>

                      <!--<el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">-->
                      <!--  <span class="ageing_b_text_l">汽车类型：</span>-->
                      <!--  <span class="ageing_b_text_r" v-if="isEdit == false">-->
                      <!--    <span v-for="(item) in dict.type.automobile_type">-->
                      <!--      <span v-if="item.value == form.basics.car_type">{{item.label}}</span>-->
                      <!--    </span>-->
                      <!--  </span>-->
                      <!--  <el-select-->
                      <!--      v-else-->
                      <!--      clearable-->
                      <!--      v-model="form.basics.car_type"-->
                      <!--      placeholder="请选择"-->
                      <!--      class="ele-fluid">-->
                      <!--    <el-option-->
                      <!--        v-for="(item) in dict.type.automobile_type"-->
                      <!--        :label="item.label"-->
                      <!--        :value="parseInt(item.value)"-->
                      <!--    />-->
                      <!--  </el-select>-->
                      <!--</el-col>-->
                      <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                        <span class="ageing_b_text_l">车架号：</span>
                        <!--<span class="ageing_b_text_r" v-if="isEdit == false">{{form.basics.vin}} </span>-->
                        <!--<el-input-->
                        <!--    style="width: 150px;"-->
                        <!--    v-else-->
                        <!--    clearable-->
                        <!--    :maxlength="20"-->
                        <!--    v-model="form.basics.vin"-->
                        <!--    placeholder="请输入车架号"/>-->
                        <span class="ageing_b_text_r" >{{form.basics.vin}} </span>
                      </el-col>
                      <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                        <span class="ageing_b_text_l">车牌号：</span>
                        <!--<span class="ageing_b_text_r" v-if="isEdit == false">{{form.basics.license_plate}}</span>-->
                        <!--<el-input-->
                        <!--    style="width: 150px;"-->
                        <!--    v-else-->
                        <!--    clearable-->
                        <!--    :maxlength="20"-->
                        <!--    v-model="form.basics.license_plate"-->
                        <!--    placeholder="请输入车牌号"/>-->
                        <span class="ageing_b_text_r">{{form.basics.license_plate}}</span>
                      </el-col>
                    </el-row>
                    <el-row style="margin: 15px;">
                      <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                        <span class="ageing_b_text_l">品牌型号：</span>
                        <!--<span class="ageing_b_text_r" v-if="isEdit == false">{{form.basics.car_brand}}</span>-->
                        <!--<el-input-->
                        <!--    style="width: 150px;"-->
                        <!--    v-else-->
                        <!--    clearable-->
                        <!--    :maxlength="20"-->
                        <!--    v-model="form.basics.car_brand"-->
                        <!--    placeholder="请输入品牌型号"/>-->
                        <span class="ageing_b_text_r">{{form.basics.car_brand}}</span>
                      </el-col>
                      <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                        <span class="ageing_b_text_l">车主：</span>
                        <span class="ageing_b_text_r" v-if="orderReceivingisEdit == false">{{form.joinInfo.owners}}</span>
                        <el-input
                            v-else
                            clearable
                            :maxlength="20"
                            v-model="form.joinInfo.owners"
                            placeholder="请输入车主姓名"/>
                      </el-col>
                      <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                        <span class="ageing_b_text_l">车主号码：</span>
                        <span class="ageing_b_text_r" style="vertical-align: middle" v-if="orderReceivingisEdit == false && form.joinInfo.owners_phone != ''">{{form.joinInfo.owners_phone}}</span>
                        <img style="width: 18px;height: 18px; vertical-align: middle;margin-top: 2px; margin-left: 5px;" src="@/assets/images/home/order/phone.png" alt="" v-if="orderReceivingisEdit == false && form.joinInfo.owners_phone != ''" @click="tel(form.joinInfo.owners_phone,'车主')">
                        <el-input
                            v-else
                            clearable
                            :maxlength="20"
                            v-model="form.joinInfo.owners_phone"
                            placeholder="请输入车主号码"/>
                      </el-col>
                      <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                        <span class="ageing_b_text_l">客户等级：</span>
                        <span class="ageing_b_text_r">{{}}</span>
                      </el-col>
                      <!--<el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">-->
                      <!--  <span class="ageing_b_text_l">汽车类型：</span>-->
                      <!--  <span class="ageing_b_text_r" v-if="isEdit == false">-->
                      <!--    <span v-for="(item) in dict.type.automobile_type">-->
                      <!--      <span v-if="item.value == form.basics.car_type">{{item.label}}</span>-->
                      <!--    </span>-->
                      <!--  </span>-->
                      <!--  <el-select-->
                      <!--      v-else-->
                      <!--      clearable-->
                      <!--      v-model="form.basics.car_type"-->
                      <!--      placeholder="请选择"-->
                      <!--      class="ele-fluid">-->
                      <!--    <el-option-->
                      <!--        v-for="(item) in dict.type.automobile_type"-->
                      <!--        :label="item.label"-->
                      <!--        :value="parseInt(item.value)"-->
                      <!--    />-->
                      <!--  </el-select>-->
                      <!--</el-col>-->
                    </el-row>
                    <el-row style="margin: 15px;">
                      <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                        <span class="ageing_b_text_l">是否预约：</span>
                        <span class="ageing_b_text_r">{{}}</span>
                      </el-col>
                      <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                        <span class="ageing_b_text_l">预约时间：</span>
                        <span class="ageing_b_text_r">{{}}</span>
                      </el-col>
                      <el-col :span="6" class="buju">
                        <span class="ageing_b_text_l">订单来源：</span>
                        <el-tooltip class="item" effect="dark" content="" placement="top">
                          <span class="ageing_b_text_r">
                            山东国寿财
                          </span>
                        </el-tooltip>
                      </el-col>
                    </el-row>
                    <el-row style="margin: 15px;">
                      <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                        <span class="ageing_b_text_l">卸车联系人：</span>
                        <span class="ageing_b_text_r" v-if="orderReceivingisEdit == false">{{form.joinInfo.unload_name}}</span>
                        <el-input
                            style="width: 150px;"
                            v-else
                            clearable
                            :maxlength="20"
                            v-model="form.joinInfo.unload_name"
                            placeholder="请输入卸车联系人"/>
                      </el-col>
                      <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                        <span class="ageing_b_text_l">卸车联系人号码：</span>
                        <span class="ageing_b_text_r" style="vertical-align: middle" v-if="orderReceivingisEdit == false">{{form.joinInfo.unload_phone}}</span>
                        <el-input
                            style="width: 100px;"
                            v-else
                            clearable
                            :maxlength="20"
                            v-model="form.joinInfo.unload_phone"
                            placeholder="请输入卸车联系人号码"/>
                        <img style="width: 18px;height: 18px; vertical-align: middle;margin-top: 2px; margin-left: 5px;" src="@/assets/images/home/order/phone.png" alt="" v-if="orderReceivingisEdit == false && form.joinInfo.unload_phone != ''" @click="tel(form.joinInfo.unload_phone,'其他')">
                      </el-col>
                    </el-row>
                    <el-row style="margin: 15px;">
                      <el-col :span="6" class="buju" style="display: flex;align-items: center;">
                        <span class="ageing_b_text_l">服务项目：</span>
                        <span class="ageing_b_text_r" v-if="isEdit == false">{{form.joinInfo.items}}</span>
                        <el-select
                            style="width: 150px !important;"
                            v-else
                            clearable
                            v-model="form.joinInfo.items"
                            placeholder="请选择"
                            class="ele-fluid">
                          <el-option v-for="(item) in ServicesAvailable" :label="item.name" :value="item.items"/>
                        </el-select>
                      </el-col>
                      <el-col :span="6" class="buju" style="display: flex;align-items: center;">
                        <span class="ageing_b_text_l">增补服务项目：</span>
                        <span class="ageing_b_text_r" v-if="isEdit == false">{{form.joinInfo.supplemental_items}}</span>
                        <el-select
                            style="width: 150px !important;"
                            v-else
                            clearable
                            v-model="form.joinInfo.supplemental_items"
                            placeholder="请选择"
                            class="ele-fluid">
                          <el-option v-for="(item) in ServicesAvailable" :label="item.name" :value="item.items"/>
                        </el-select>
                      </el-col>
                      <!--加小轮时展示-->
                      <el-col :span="6" style="margin-top: 2px;display: flex;align-items: center;">
                        <span class="ageing_b_text_l">数量：</span>
                        <span class="ageing_b_text_r">{{}}</span>
                      </el-col>
                      <el-col :span="6" style="margin-top: 2px;display: flex;align-items: center;">
                        <span class="ageing_b_text_l">订单属性：</span>
                        <span class="ageing_b_text_r">{{form.joinInfo.order_attribute_name}}</span>
                      </el-col>
                    </el-row>
                    <el-row style="margin: 15px;">
                      <el-col :span="6" class="buju">
                        <span class="ageing_b_text_l">下单案件地：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.starting_point" placement="top">
                          <span class="ageing_b_text_r">
                            {{form.basics.starting_point}}
                          </span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" class="buju" v-if="form.odd_even_address != 'single'">
                        <span class="ageing_b_text_l">下单目的地：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{form.basics.destination}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" class="buju" style="align-items: center;">
                        <span class="ageing_b_text_l">实际救援案件地：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.starting_point" placement="top">
                          <span class="ageing_b_text_r" v-if="isEdit == false">
                            {{form.basics.starting_point}}
                          </span>
                          <div v-else class="xuanzedidianyangshi" @click="opensiteselection(0)">
                            <span v-if="form.basics.starting_point == undefined">点击选择</span>
                            <el-tooltip class="item" effect="dark" :content="form.basics.starting_point" placement="top">
                              <div class="didian">{{form.basics.starting_point}}</div>
                            </el-tooltip>
                            <i class="el-icon-location-information"></i>
                          </div>
                        </el-tooltip>
                        <!--<el-button v-else @click="opensiteselection(0)">点击选择</el-button>-->
                      </el-col>
                      <el-col :span="6" class="buju" style="align-items: center;" v-if="form.odd_even_address != 'single'">
                        <span class="ageing_b_text_l">实际救援目的地：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r" v-if="isEdit == false">{{form.basics.destination}}</span>
                          <div v-else class="xuanzedidianyangshi" @click="opensiteselection(1)">
                            <span v-if="form.basics.destination == undefined">请选择救援终点</span>
                            <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                              <div class="didian">{{form.basics.destination}}</div>
                            </el-tooltip>
                            <i class="el-icon-location-information"></i>
                          </div>
                        </el-tooltip>
                        <!--<el-button v-else @click="opensiteselection(1)">点击选择</el-button>-->
                      </el-col>
                    </el-row>
                    <!--自定义表单数据循环-->
                    <el-row style="margin: 15px;">
                      <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju" v-for="(item,index) in form.custom_fields" :key="index">
                        <span class="ageing_b_text_l">{{item.label}}：</span>
                        <span class="ageing_b_text_r">{{item.value}}</span>
                      </el-col>
                    </el-row>
                  </div>
                </div>

            <!--结算信息-->
            <div class="ageing" style="margin-top: 20px;" id="jiesuanxinxi">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    客户结算信息
                  </div>
                </div>
                <div>
                  <div class="custom" style="margin-left: 20px">
                    <el-button class="custom-button" v-if="settlementEdit == false" @click="settlement">
                      <!--el-icon-devops 是自己命名的-->
                      <i class="el-icon-bianji" />
                      <span style="vertical-align: middle">编辑</span>
                    </el-button>
                    <div v-else>
                      <el-button class="custom-button">
                        <span style="vertical-align: middle" @click="settlementConfirm()">确认</span>
                      </el-button>
                      <el-button @click="settlementCancel()">取消</el-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ageing_b">
                <el-row style="margin: 15px;">
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">服务项目：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r" v-if="settlementEdit == false">{{form.joinInfo.items}}</span>
                      <el-select
                        v-else
                        clearable
                        v-model="form.joinInfo.items"
                        placeholder="请选择"
                        class="ele-fluid">
                        <el-option v-for="(item) in ServicesAvailable" :label="item.name" :value="item.items"/>
                      </el-select>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">服务价格：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">增补项目：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r" v-if="settlementEdit == false">{{form.joinInfo.supplemental_items}}</span>
                      <el-select
                        v-else
                        clearable
                        v-model="form.joinInfo.supplemental_items"
                        placeholder="请选择"
                        class="ele-fluid">
                        <el-option v-for="(item) in ServicesAvailable" :label="item.name" :value="item.items"/>
                      </el-select>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">增补项目价格：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <!--<span class="ageing_b_text_r">{{}}</span>-->
                      <span class="ageing_b_text_r" v-if="settlementEdit == false">{{}}</span>
                      <el-input
                        v-else
                        placeholder="请输入内容"
                        v-model="form.jiage"
                        clearable>
                      </el-input>
                    </el-tooltip>
                  </el-col>
                </el-row>
                <el-row style="margin: 15px;">
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">结算状态：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">订单总金额：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">计价方式：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">结算方式：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                </el-row>
                <el-row style="margin: 15px;">
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">结算总金额：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" class="buju">
                    <span class="ageing_b_text_l">订单来源：</span>
                    <el-tooltip class="item" effect="dark" content="" placement="top">
                      <span class="ageing_b_text_r">
                        山东国寿财
                      </span>
                    </el-tooltip>
                  </el-col>
                </el-row>
              </div>
            </div>

                <!--受理信息-->
                <div class="ageing" style="margin-top: 20px;" id="jiedanxinxi">
                  <div class="Datalist">
                    <div class="Datalist_l">
                      <div class="Datalist_icon"></div>
                      <div class="Datalist_text">
                        受理信息
                      </div>
                    </div>
                    <div>
                      <!--不能编辑-->
                      <!--<div class="custom" style="margin-left: 20px">-->
                      <!--  <el-button class="custom-button" v-if="orderReceivingisEdit == false" @click="orderReceiving()" v-auths="[`${$config.uniquePrefix}orderModule:info:serveinfoedit`]">-->
                      <!--    &lt;!&ndash;el-icon-devops 是自己命名的&ndash;&gt;-->
                      <!--    <i class="el-icon-bianji" />-->
                      <!--    <span style="vertical-align: middle">编辑</span>-->
                      <!--  </el-button>-->
                      <!--  <div v-else>-->
                      <!--    <el-button class="custom-button" @click="orderReceivingConfirm()" v-auths="[`${$config.uniquePrefix}orderModule:info:serveinfoedit`]">-->
                      <!--      <span style="vertical-align: middle">确认</span>-->
                      <!--    </el-button>-->
                      <!--    <el-button @click="orderReceivingCancel()">取消</el-button>-->
                      <!--  </div>-->
                      <!--</div>-->
                    </div>
                  </div>
                  <div class="ageing_b" style="padding-left: 20px; padding-right: 20px;">
                    <el-row style="margin: 15px;">
                      <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                        <span class="ageing_b_text_l">所属公司：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                        <span class="ageing_b_text_l">所属部门：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                        <span class="ageing_b_text_l">受理员：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                        <span class="ageing_b_text_l">派单员：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                    </el-row>
                    <el-row style="margin: 15px;">
                      <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                        <span class="ageing_b_text_l">质检员：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                        <span class="ageing_b_text_l">服务商创建人：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                        <span class="ageing_b_text_l">服务商创建所属部门：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                        <span class="ageing_b_text_l">客户创建关联部门：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                    </el-row>
                    <el-row style="margin: 15px;">
                      <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                        <span class="ageing_b_text_l">客户创建人：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                        <span class="ageing_b_text_l">初审人：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                        <span class="ageing_b_text_l">复审人：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                        <span class="ageing_b_text_l">核销人：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                    </el-row>

                    <el-row style="margin: 15px;">
                      <el-col :span="6" class="buju">
                        <span class="ageing_b_text_l">系统受理人：</span>
                        <span class="ageing_b_text_r">{{form.joinInfo.acceptor_nickname}}</span>
                      </el-col>
                      <el-col :span="6" class="buju">
                        <span class="ageing_b_text_l">派单人：</span>
                        <span class="ageing_b_text_r">{{form.joinInfo.send_nickname}}</span>
                      </el-col>
                    </el-row>
                    <el-row style="margin: 15px;">
                      <el-col :span="6" class="buju">
                        <span class="ageing_b_text_l">跟进人：</span>
                        <span class="ageing_b_text_r">{{form.joinInfo.loss_assessor_name}}</span>
                      </el-col>
                      <el-col :span="6" class="buju">
                        <span class="ageing_b_text_l">回访人：</span>
                        <span class="ageing_b_text_r">{{form.joinInfo.visit_nickname}}</span>
                      </el-col>
                    </el-row>
                  </div>
                </div>


                <!--服务商信息-->
                <div class="ageing" style="margin-top: 20px;" id="jiedanxinxi">
                  <div class="Datalist">
                    <div class="Datalist_l">
                      <div class="Datalist_icon"></div>
                      <div class="Datalist_text">
                        服务商信息
                      </div>
                    </div>
                    <div>
                      <div class="custom" style="margin-left: 20px">
                        <!--<el-button class="custom-button" v-if="orderReceivingisEdit == false">-->
                        <!--  &lt;!&ndash;el-icon-devops 是自己命名的&ndash;&gt;-->
                        <!--  <i class="el-icon-bianji" />-->
                        <!--  <span style="vertical-align: middle" @click="orderReceiving()">编辑</span>-->
                        <!--</el-button>-->
                        <!--<div v-else>-->
                        <!--  <el-button class="custom-button">-->
                        <!--    <span style="vertical-align: middle" @click="orderReceivingConfirm()">确认</span>-->
                        <!--  </el-button>-->
                        <!--  <el-button @click="orderReceivingCancel()">取消</el-button>-->
                        <!--</div>-->
                      </div>
                    </div>
                  </div>
                  <div class="ageing_b" style="padding-left: 20px; padding-right: 20px;">
                    <div class="fuwuinfo" style="margin-top: 0;">
                      <el-row style="margin: 15px;">
                        <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                          <span class="ageing_b_text_l">服务单位：</span>
                          <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                            <span class="ageing_b_text_r">{{}}</span>
                          </el-tooltip>
                        </el-col>
                        <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                          <span class="ageing_b_text_l">所在区域：</span>
                          <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                            <span class="ageing_b_text_r">{{}}</span>
                          </el-tooltip>
                        </el-col>
                        <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                          <span class="ageing_b_text_l">客服电话：</span>
                          <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                            <span class="ageing_b_text_r">{{}}</span>
                          </el-tooltip>
                        </el-col>
                        <el-col :span="6" class="buju">
                          <span class="ageing_b_text_l">负责人电话：</span>
                          <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                            <span class="ageing_b_text_r">{{}}</span>
                          </el-tooltip>
                        </el-col>
                      </el-row>
                      <el-row style="margin: 15px;">
                        <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                          <span class="ageing_b_text_l">服务商接单次数：</span>
                          <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                            <span class="ageing_b_text_r">{{}}</span>
                          </el-tooltip>
                        </el-col>
                        <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                          <span class="ageing_b_text_l">服务商调度员：</span>
                          <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                            <span class="ageing_b_text_r">{{}}</span>
                          </el-tooltip>
                        </el-col>
                        <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                          <span class="ageing_b_text_l">合作价格：</span>
                          <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                            <span class="ageing_b_text_r">{{}}</span>
                          </el-tooltip>
                        </el-col>
                        <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                          <span class="ageing_b_text_l">完成数量：</span>
                          <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                            <span class="ageing_b_text_r">{{}}</span>
                          </el-tooltip>
                        </el-col>
                      </el-row>
                      <el-row style="margin: 15px;">
                        <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                          <span class="ageing_b_text_l">扣减金额：</span>
                          <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                            <span class="ageing_b_text_r">{{}}</span>
                          </el-tooltip>
                        </el-col>
                        <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                          <span class="ageing_b_text_l">增补价格：</span>
                          <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                            <span class="ageing_b_text_r">{{}}</span>
                          </el-tooltip>
                        </el-col>
                        <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                          <span class="ageing_b_text_l">溢价次数：</span>
                          <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                            <span class="ageing_b_text_r">{{}}</span>
                          </el-tooltip>
                        </el-col>
                        <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                          <span class="ageing_b_text_l">议价总金额：</span>
                          <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                            <span class="ageing_b_text_r">{{}}</span>
                          </el-tooltip>
                        </el-col>
                      </el-row>
                      <el-row style="margin: 15px;">
                        <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                          <span class="ageing_b_text_l">应结算总金额：</span>
                          <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                            <span class="ageing_b_text_r">{{}}</span>
                          </el-tooltip>
                        </el-col>
                        <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                          <span class="ageing_b_text_l">企业是否认证：</span>
                          <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                            <span class="ageing_b_text_r">{{}}</span>
                          </el-tooltip>
                        </el-col>
                        <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                          <span class="ageing_b_text_l">企业税点：</span>
                          <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                            <span class="ageing_b_text_r">{{}}</span>
                          </el-tooltip>
                        </el-col>
                        <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                          <span class="ageing_b_text_l">拒单数量：</span>
                          <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                            <span class="ageing_b_text_r">{{}}</span>
                          </el-tooltip>
                        </el-col>
                      </el-row>
                      <el-row style="margin: 15px;">
                        <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                          <span class="ageing_b_text_l">公户提现次数：</span>
                          <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                            <span class="ageing_b_text_r">{{}}</span>
                          </el-tooltip>
                        </el-col>
                        <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                          <span class="ageing_b_text_l">公户提现总金额：</span>
                          <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                            <span class="ageing_b_text_r">{{}}</span>
                          </el-tooltip>
                        </el-col>
                        <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                          <span class="ageing_b_text_l">提现方式：</span>
                          <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                            <span class="ageing_b_text_r">{{}}</span>
                          </el-tooltip>
                        </el-col>
                        <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                          <span class="ageing_b_text_l">结算方式：</span>
                          <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                            <span class="ageing_b_text_r">{{form.joinInfo.channelInfo.means_payments_name}}</span>
                          </el-tooltip>
                        </el-col>
                      </el-row>
                    </div>
                  </div>
                </div>

            <!--服务商结算信息-->
            <div class="ageing" style="margin-top: 20px;" id="jiedanxinxi">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    服务商结算信息
                  </div>
                </div>
                <div>
                  <div class="custom" style="margin-left: 20px">
                    <el-button class="custom-button" v-if="orderReceivingisEdit1 == false" @click="orderReceiving1()" v-auths="[`${$config.uniquePrefix}orderModule:info:serveinfoedit`]">
                      <!--el-icon-devops 是自己命名的-->
                      <i class="el-icon-bianji" />
                      <span style="vertical-align: middle">编辑</span>
                    </el-button>
                    <div v-else>
                      <el-button class="custom-button" @click="orderReceivingConfirm1()" v-auths="[`${$config.uniquePrefix}orderModule:info:serveinfoedit`]">
                        <span style="vertical-align: middle">确认</span>
                      </el-button>
                      <el-button @click="orderReceivingCancel1()">取消</el-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ageing_b" style="padding-left: 20px; padding-right: 20px;">
                <el-row style="margin: 15px;">
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">订单来源：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">合作价格：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">超时罚款金额：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">赔付金额：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                </el-row>
                <el-row style="margin: 15px;">
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">溢价金额：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">结算状态：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">结算流水号：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" class="buju">
                    <span class="ageing_b_text_l">订单补扣款项：</span>
                    <span class="ageing_b_text_r">
                            <span v-for="(item,index) in form.facilitatorSettlementInformation.thread_log">
                              {{item}}
                            </span>
                          </span>
                  </el-col>
                </el-row>
                <!--<el-row style="color: #ff9b05;margin-top: 10px;">-->
                <!--  <span>* 议价时展示,自动赋值到下边收款</span>-->
                <!--</el-row>-->
                <el-row style="margin: 15px;">
                  <el-col :span="6" style="display: flex;align-items: center;">
                    <span class="ageing_b_text_l">结算金额：</span>
                    <span class="ageing_b_text_r">{{form.facilitatorSettlementInformation.contract_price}}</span>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">增补项目：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">增补价格：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" class="buju">
                    <span class="ageing_b_text_l">订单补扣款金额：</span>
                    <span class="ageing_b_text_r">{{form.facilitatorSettlementInformation.repair_thread_price}}</span>
                  </el-col>
                </el-row>
                <el-row style="margin: 15px;">
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">结算方式：</span>
                    <span class="ageing_b_text_r" style="color: #FF9B05;" v-if="orderReceivingisEdit1 == false">{{form.joinInfo.channelInfo.means_payments_name}}</span>
                    <el-select
                        style="width: 150px !important;"
                        v-else
                        clearable
                        v-model="form.joinInfo.channelInfo.means_payments"
                        placeholder="请选择"
                        class="ele-fluid">
                      <el-option
                          v-for="(item) in dict.type.valuationclearing_form"
                          :label="item.label"
                          :value="item.value"
                      />
                    </el-select>
                  </el-col>
                  <el-col :span="6" class="" style="display: flex;align-items: center;">
                    <span class="ageing_b_text_l">计价方式：</span>
                    <span class="ageing_b_text_r" v-if="orderReceivingisEdit1 == false">{{form.joinInfo.channelInfo.valuation_mode_name}}</span>
                    <el-select
                        style="width: 150px !important;"
                        v-else
                        clearable
                        :disabled="disabled"
                        v-model="form.joinInfo.channelInfo.valuation_mode"
                        placeholder="选择系统计价/仪价"
                        class="ele-fluid">
                      <el-option label="系统计价" value="system"/>
                      <el-option label="仪价" value="bargain"/>
                    </el-select>
                  </el-col>
                  <el-col :span="6" class="buju">
                    <span class="ageing_b_text_l">议价金额：</span>
                    <span class="ageing_b_text_r">{{form.facilitatorSettlementInformation.bargain_price}}</span>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">订单应结算总金额：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                </el-row>
                <el-row style="margin: 15px;">
                  <el-col :span="6" class="buju">
                    <span class="ageing_b_text_l">结算状态：</span>
                    <span class="ageing_b_text_r">{{form.facilitatorSettlementInformation.settle_accounts_status}}</span>
                  </el-col>
                  <el-col :span="6" class="buju">
                    <span class="ageing_b_text_l">议价原因：</span>
                    <span class="ageing_b_text_r">{{form.facilitatorSettlementInformation.bargain_reason}}</span>
                  </el-col>
                </el-row>
                <!--<el-row :gutter="15" v-if="form.joinInfo.channelInfo.valuation_mode == 'bargain' && orderReceivingisEdit1 == true" style="margin-top: 15px;">-->
                <!--  <el-col :span="6" style="display:flex;align-items: center;">-->
                <!--    <span class="ageing_b_text_l">结算金额：</span>-->
                <!--    &lt;!&ndash;<el-input&ndash;&gt;-->
                <!--    &lt;!&ndash;    style="width: 50px;"&ndash;&gt;-->
                <!--    &lt;!&ndash;    clearable&ndash;&gt;-->
                <!--    &lt;!&ndash;    :maxlength="20"&ndash;&gt;-->
                <!--    &lt;!&ndash;    v-model="form.joinInfo.receivable_amount"&ndash;&gt;-->
                <!--    &lt;!&ndash;    placeholder="请输入"/>&ndash;&gt;-->
                <!--    <span class="ageing_b_text_r">{{form.joinInfo.receivable_amount}}</span>-->
                <!--    <span class="ageing_b_text_r">元</span>-->
                <!--  </el-col>-->
                <!--  <el-col :span="6" style="display:flex;align-items: center;">-->
                <!--    <span class="ageing_b_text_l">议价金额：</span>-->
                <!--    <el-input-->
                <!--        style="width: 100px;"-->
                <!--        clearable-->
                <!--        :maxlength="20"-->
                <!--        v-model="form.joinInfo.bargain_price"-->
                <!--        placeholder="请输入"/>-->
                <!--    <span class="ageing_b_text_r">元</span>-->
                <!--  </el-col>-->
                <!--  <el-col :span="6" style="display: flex;align-items: center;">-->
                <!--    <span class="ageing_b_text_l">合计金额：</span>-->
                <!--    &lt;!&ndash;<el-input&ndash;&gt;-->
                <!--    &lt;!&ndash;    style="width: 50px;"&ndash;&gt;-->
                <!--    &lt;!&ndash;    clearable&ndash;&gt;-->
                <!--    &lt;!&ndash;    :maxlength="20"&ndash;&gt;-->
                <!--    &lt;!&ndash;    v-model="form.joinInfo.total_amount"&ndash;&gt;-->
                <!--    &lt;!&ndash;    placeholder="请输入"/>&ndash;&gt;-->
                <!--    <span class="ageing_b_text_r">{{form.joinInfo.total_amount}}</span>-->
                <!--    <span class="ageing_b_text_r">元</span>-->
                <!--  </el-col>-->
                <!--</el-row>-->
                <!--<el-row :gutter="15"  v-if="form.joinInfo.channelInfo.valuation_mode == 'bargain' && orderReceivingisEdit1 == true" style="margin-top: 15px;">-->
                <!--  <el-col :span="12">-->
                <!--    <div style="display: flex;align-items: center;">-->
                <!--      <span class="ageing_b_text_l">议价原因：</span>-->
                <!--      <el-input-->
                <!--          clearable-->
                <!--          :maxlength="20"-->
                <!--          v-model="form.joinInfo.bargain_reason"-->
                <!--          placeholder="请输入"/>-->
                <!--    </div>-->
                <!--  </el-col>-->
                <!--</el-row>-->
              </div>
            </div>

            <!--司机接待信息-->
            <div class="ageing" style="margin-top: 20px;" id="jiedanxinxi">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    司机接单信息
                  </div>
                </div>
                <div>
                  <div class="custom" style="margin-left: 20px">
                    <el-button class="custom-button" v-if="sijiedit == false" @click="sijieditedit()" >
                      <!--el-icon-devops 是自己命名的-->
                      <i class="el-icon-bianji" />
                      <span style="vertical-align: middle">编辑</span>
                    </el-button>
                    <div v-else>
                      <el-button class="custom-button" @click="sijieditqueding()">
                        <span style="vertical-align: middle">确认</span>
                      </el-button>
                      <el-button @click="sijieditquxiao()">取消</el-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ageing_b" style="padding-left: 20px; padding-right: 20px;">
                <el-row style="margin: 15px;">
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">接单司机：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">司机电话：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">司机身份证号：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">司机类型：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                </el-row>
                <el-row style="margin: 15px;">
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">救援车牌号：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">驾驶证类型：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">是否实名：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">接单方式：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{form.assessmentInformation.driver_operation_mode}}</span>
                    </el-tooltip>
                  </el-col>
                </el-row>
                <el-row style="margin: 15px;">
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">司机属性：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">司机计价类型：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <!--结算金额需要修改-->
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">结算金额：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r" v-if="sijiedit == false">{{}}</span>
                      <el-input
                          v-else
                          placeholder="请输入内容"
                          v-model="form.input"
                          clearable>
                      </el-input>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">议价金额：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                </el-row>
                <el-row style="margin: 15px;">
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">结算总金额：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r" v-if="sijiedit == false">{{}}</span>
                      <el-input
                          v-else
                          placeholder="请输入内容"
                          v-model="form.input"
                          clearable>
                      </el-input>
                    </el-tooltip>
                  </el-col>
                </el-row>
              </div>
            </div>

            <!--收款信息-->
            <div class="ageing" style="margin-top: 20px;" id="jiedanxinxi">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    收款信息
                  </div>
                </div>
                <div>
                  <div class="custom" style="margin-left: 20px">
                    <el-button class="custom-button" @click="shoukuaneditbianji()" >
                      <!--el-icon-devops 是自己命名的-->
                      <i class="el-icon-bianji" />
                      <span style="vertical-align: middle">编辑</span>
                    </el-button>
                  </div>
                </div>
              </div>
              <div class="ageing_b" style="padding-left: 20px; padding-right: 20px;">
                <el-row style="margin: 15px;">
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l"> 银行账号：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">开户行名称：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">持卡人姓名：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">金额：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                </el-row>
                <el-row style="margin: 15px;">
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">线下支单 ：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l"> 车主车牌号：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">转账流水号：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                </el-row>
              </div>
            </div>


            <!--聚合信息-->
            <div class="ageing" style="margin-top: 20px;" id="jiedanxinxi">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    聚合信息
                  </div>
                </div>
              </div>
              <div class="ageing_b" style="padding-left: 20px; padding-right: 20px;">
                <el-row style="margin: 15px;">
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">聚合司机：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">手机号：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">聚合直线距离：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">聚合规划距离：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                </el-row>
                <el-row style="margin: 15px;">
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">聚合坐标：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">聚合位置：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">聚合车牌号：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">聚合状态：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                </el-row>
                <el-row style="margin: 15px;">
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">实际服务车牌号：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                </el-row>
              </div>
            </div>

                <!--司机考核信息-->
                <div class="ageing" style="margin-top: 20px;" id="kaohexinxi">
                  <div class="Datalist">
                    <div class="Datalist_l">
                      <div class="Datalist_icon"></div>
                      <div class="Datalist_text">
                        司机考核信息
                      </div>
                    </div>
                    <div>
                      <!--<div class="custom" style="margin-left: 20px">-->
                      <!--  <el-button class="custom-button">-->
                      <!--    &lt;!&ndash;el-icon-devops 是自己命名的&ndash;&gt;-->
                      <!--    <i class="el-icon-bianji" />-->
                      <!--    <span style="vertical-align: middle">编辑</span>-->
                      <!--  </el-button>-->
                      <!--</div>-->
                    </div>
                  </div>
                  <!--<div class="ageing_b">-->
                  <!--  <el-row style="margin: 15px;">-->
                  <!--    <el-col :span="4" style="text-align: center" class="buju">-->
                  <!--      <img v-if="form.assessmentInformation.timely_accomplish == true" src="@/assets/images/home/order/chenggong.png" alt="" style="width: 24px;height: 24px;vertical-align: middle;">-->
                  <!--      <img v-else src="@/assets/images/home/order/shibai.png" alt="" style="width: 24px;height: 24px;vertical-align: middle;">-->
                  <!--      <span style="font-size: 16px;font-weight: 400;text-align: right;color: #050505; vertical-align: middle; margin-left: 8px;">{{form.assessmentInformation.timely_accomplish_name}}  {{form.assessmentInformation.timely_accomplish_time}}</span>-->
                  <!--    </el-col>-->
                  <!--    <el-col :span="4" style="text-align: center" class="buju">-->
                  <!--      <img v-if="form.assessmentInformation.timely_join == true" src="@/assets/images/home/order/chenggong.png" alt="" style="width: 24px;height: 24px;vertical-align: middle;">-->
                  <!--      <img v-else src="@/assets/images/home/order/shibai.png" alt="" style="width: 24px;height: 24px;vertical-align: middle;">-->
                  <!--      <span style="font-size: 16px;font-weight: 400;text-align: right;color: #050505; vertical-align: middle; margin-left: 8px;">{{form.assessmentInformation.timely_join_name}}  {{form.assessmentInformation.timely_join_time}}</span>-->
                  <!--    </el-col>-->
                  <!--    <el-col :span="4" style="text-align: center" class="buju">-->
                  <!--      <img v-if="form.assessmentInformation.timely_response == true" src="@/assets/images/home/order/chenggong.png" alt="" style="width: 24px;height: 24px;vertical-align: middle;">-->
                  <!--      <img v-else src="@/assets/images/home/order/shibai.png" alt="" style="width: 24px;height: 24px;vertical-align: middle;">-->
                  <!--      <span style="font-size: 16px;font-weight: 400;text-align: right;color: #050505; vertical-align: middle; margin-left: 8px;">{{form.assessmentInformation.timely_response_name}}  {{form.assessmentInformation.timely_join_time}}</span>-->
                  <!--    </el-col>-->
                  <!--    <el-col :span="4" style="text-align: center" class="buju">-->
                  <!--      <img v-if="form.assessmentInformation.timely_scene == true" src="@/assets/images/home/order/chenggong.png" alt="" style="width: 24px;height: 24px;vertical-align: middle;">-->
                  <!--      <img v-else src="@/assets/images/home/order/shibai.png" alt="" style="width: 24px;height: 24px;vertical-align: middle;">-->
                  <!--      <span style="font-size: 16px;font-weight: 400;text-align: right;color: #050505; vertical-align: middle; margin-left: 8px;">{{form.assessmentInformation.timely_scene_name}}  {{form.assessmentInformation.timely_scene_time}}</span>-->
                  <!--    </el-col>-->
                  <!--    &lt;!&ndash;<el-col :span="4" style="text-align: center" class="buju">&ndash;&gt;-->
                  <!--    &lt;!&ndash;  <img src="@/assets/images/home/order/chenggong.png" alt="" style="width: 24px;height: 24px;vertical-align: middle;">&ndash;&gt;-->
                  <!--    &lt;!&ndash;  <span style="font-size: 16px;font-weight: 400;text-align: right;color: #050505; vertical-align: middle; margin-left: 8px;">及时响应  2分12秒</span>&ndash;&gt;-->
                  <!--    &lt;!&ndash;</el-col>&ndash;&gt;-->
                  <!--    &lt;!&ndash;<el-col :span="4" style="text-align: center" class="buju">&ndash;&gt;-->
                  <!--    &lt;!&ndash;  <img src="@/assets/images/home/order/chenggong.png" alt="" style="width: 24px;height: 24px;vertical-align: middle;">&ndash;&gt;-->
                  <!--    &lt;!&ndash;  <span style="font-size: 16px;font-weight: 400;text-align: right;color: #050505; vertical-align: middle; margin-left: 8px;">及时响应  2分12秒</span>&ndash;&gt;-->
                  <!--    &lt;!&ndash;</el-col>&ndash;&gt;-->
                  <!--  </el-row>-->
                  <!--</div>-->
                  <div class="ageing_b">
                    <el-row style="margin: 15px;">
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">受理时间：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">合作商派单时间：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">司机接单时间：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">司机出发时间：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                    </el-row>
                    <el-row style="margin: 15px;">
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">司机联系客户时间：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">司机空驶位置时间：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">司机跟单时间：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">系统催单司机时间：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                    </el-row>
                    <el-row style="margin: 15px;">
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">司机到达案件地时间：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">司机现场拍照时间：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">司机出发目的地时间：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">司机到达目的地时间：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                    </el-row>
                    <el-row style="margin: 15px;">
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">司机完成时间：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">客户好评时间：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">聚合派单到司机时间：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">聚合到司机改派司机时间：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                    </el-row>
                    <el-row style="margin: 15px;">
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">聚合司机完成时间：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                    </el-row>
                    <!--<el-row style="margin: 15px;">-->
                    <!--  <el-col :span="6" style="text-align: center" class="buju">-->
                    <!--    <span class="ageing_b_text_l">派单时间：</span>-->
                    <!--    <span class="ageing_b_text_r">{{form.assessmentInformation.dispatch_time}}</span>-->
                    <!--  </el-col>-->
                    <!--  <el-col :span="6" style="text-align: center" class="buju">-->
                    <!--    <span class="ageing_b_text_l">接单时间：</span>-->
                    <!--    <span class="ageing_b_text_r">{{form.assessmentInformation.receiving_time}}</span>-->
                    <!--  </el-col>-->
                    <!--  <el-col :span="6" style="text-align: center" class="buju">-->
                    <!--    <span class="ageing_b_text_l">到达时间：</span>-->
                    <!--    <span class="ageing_b_text_r">{{form.assessmentInformation.scene_time}}</span>-->
                    <!--  </el-col>-->
                    <!--  <el-col :span="6" style="text-align: center" class="buju">-->
                    <!--    <span class="ageing_b_text_l">完成时间：</span>-->
                    <!--    <span class="ageing_b_text_r">{{form.assessmentInformation.finish_time}}</span>-->
                    <!--  </el-col>-->
                    <!--</el-row>-->
                    <!--<el-row style="margin: 15px;">-->
                    <!--  <el-col :span="6" style="text-align: center" class="buju">-->
                    <!--    <span class="ageing_b_text_l">执行人出发时间：</span>-->
                    <!--    <span class="ageing_b_text_r">{{form.assessmentInformation.departure_time}}</span>-->
                    <!--  </el-col>-->
                    <!--</el-row>-->
                  </div>
                </div>

            <!--调度考核信息-->
            <div class="ageing" style="margin-top: 20px;" id="kaohexinxi">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    调度考核信息
                  </div>
                </div>
                <div>
                </div>
              </div>
              <div class="ageing_b" style="margin-top: 15px;">
                <el-row style="margin: 15px;">
                  <el-col :span="6" style="text-align: center" class="buju">
                    <span class="ageing_b_text_l">派单时间为2分钟：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center" class="buju">
                    <span class="ageing_b_text_l">考核时效4分钟：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center" class="buju">
                    <span class="ageing_b_text_l">司机接单时间 5分钟：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center" class="buju">
                    <span class="ageing_b_text_l">考核时效为 8分钟 ：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                </el-row>
                <el-row style="margin: 15px;">
                  <el-col :span="6" style="text-align: center" class="buju">
                    <span class="ageing_b_text_l"> 到达时间35分钟：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center" class="buju">
                    <span class="ageing_b_text_l">考核时间40 分钟：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center" class="buju">
                    <span class="ageing_b_text_l">完成时间为1小时30分钟：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center" class="buju">
                    <span class="ageing_b_text_l">跟单时效：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                </el-row>
                <el-row style="margin: 15px;">
                  <el-col :span="6" style="text-align: center" class="buju">
                    <span class="ageing_b_text_l">回访时间完成1小时40分钟：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center" class="buju">
                    <span class="ageing_b_text_l">考核时效1小时55分钟：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center" class="buju">
                    <span class="ageing_b_text_l">初审时间：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center" class="buju">
                    <span class="ageing_b_text_l">为订单完成后的2小时分钟：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                </el-row>
                <el-row style="margin: 15px;">
                  <el-col :span="6" style="text-align: center" class="buju">
                    <span class="ageing_b_text_l">考核时效为2小时10分钟分钟：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="text-align: center" class="buju">
                    <span class="ageing_b_text_l">严重超时：</span>
                    <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                      <span class="ageing_b_text_r">{{}}</span>
                    </el-tooltip>
                  </el-col>
                </el-row>
              </div>
            </div>

                <!--公里信息-->
                <div class="ageing" style="margin-top: 20px;" id="gonglixinxi">
                  <div class="Datalist">
                    <div class="Datalist_l">
                      <div class="Datalist_icon"></div>
                      <div class="Datalist_text">
                        公里信息
                      </div>
                    </div>
                    <div>
                      <div class="custom" style="margin-left: 20px">
                        <!--<el-button class="custom-button">-->
                        <!--  &lt;!&ndash;el-icon-devops 是自己命名的&ndash;&gt;-->
                        <!--  <i class="el-icon-bianji" />-->
                        <!--  <span style="vertical-align: middle">编辑</span>-->
                        <!--</el-button>-->
                      </div>
                    </div>
                  </div>
                  <div class="ageing_b">
                    <el-row style="margin: 15px;">
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">服务单位：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span style="color: #FFFFFF;">占位</span>
                      </el-col>
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">服务司机：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                    </el-row>
                    <el-row style="margin: 15px;">
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">预计到达现场公里数：</span>
                        <span class="ageing_b_text_r">{{form.kilometerInformation.expected_arrival_course}}</span>
                      </el-col>
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">实际到达目的地公里数：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">预计费用：</span>
                        <span class="ageing_b_text_r">￥{{form.kilometerInformation.expected_price}}</span>
                      </el-col>
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l" style="font-size: 16px;font-weight: 400;text-align: right;color: #242832;">预计总里程：</span>
                        <span class="ageing_b_text_r" style="font-weight: 700;">{{form.kilometerInformation.expected_total_distance}}</span>
                      </el-col>
                    </el-row>
                    <el-row style="margin: 15px;">
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">实际到达现场公里数：</span>
                        <span class="ageing_b_text_r">{{form.kilometerInformation.actual_arrival_course}}</span>
                      </el-col>
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">预计到达目的地公里数：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">实际费用：</span>
                        <span class="ageing_b_text_r">￥{{form.kilometerInformation.actual_price}}</span>
                      </el-col>
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l" style="font-size: 16px;font-weight: 400;text-align: right;color: #242832;">实际总里程：</span>
                        <span class="ageing_b_text_r" style="color: #FF9B05; font-weight: 700;">{{form.kilometerInformation.actual_total_distance}}</span>
                      </el-col>
                    </el-row>
                    <el-row style="margin: 15px;">
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">到达现场OCR识别经纬度：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">到达现场识别车牌号：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">到达现场效验：</span>
                        <span class="ageing_b_text_r">{{form.kilometerInformation.field_calibration_msg}} </span>
                      </el-col>
                      <el-col :span="6" style="text-align: center" class="buju">
                        <el-radio-group v-model="project" @change="selectproject">
                          <el-radio label="apply">申请规划</el-radio>
                          <el-radio label="practical">实际规划</el-radio>
                        </el-radio-group>
                      </el-col>
                    </el-row>
                    <!-- 地图 -->
                    <div style="height: 300px;" ref="element">
                      <AMapLoader v-if="lazyLoading === true" :msgData="msgData" v-bind:locationorigin="locationorigin" :locationdestination="locationdestination" :coord="coord" :is="currentComponent"></AMapLoader>
                    </div>
                  </div>
                </div>

                <!--回访信息-->
                <div class="ageing" style="margin-top: 20px;" id="huifangxinxi">
                  <div class="Datalist">
                    <div class="Datalist_l">
                      <div class="Datalist_icon"></div>
                      <div class="Datalist_text">
                        回访信息
                      </div>
                    </div>
                  </div>
                  <div class="ageing_b" style="padding-left: 20px; padding-right: 20px;">
                    <el-row style="margin: 15px;">
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">被保险人：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">客户：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">救援类型：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">回访人：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                    </el-row>
                    <el-row style="margin: 15px;">
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">客户是否满意：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">投诉意向：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">客户以投诉：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">投诉内容：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                    </el-row>
                    <el-row style="margin: 15px;">
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">客诉处理人：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">处理结果：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">处理费用：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6" style="text-align: center" class="buju">
                        <span class="ageing_b_text_l">处理方式：</span>
                        <el-tooltip class="item" effect="dark" :content="form.basics.destination" placement="top">
                          <span class="ageing_b_text_r">{{}}</span>
                        </el-tooltip>
                      </el-col>
                    </el-row>

                    <el-row style="background: #fafafa; padding: 15px; margin-top: 15px;" v-for="(item,index) in form.visit_log" :key="index" :gutter="15">
                      <div style="font-size: 16px;font-weight: 400;color: #4b4b4b;">
                        <el-col :span="8">
                          <span>回访满意度：</span>
                          <span v-for="(item1) in dict.type.satisfaction">
                            <span v-if="item1.value == item.satisficing">{{item1.label}}</span>
                          </span>
                        </el-col>
                        <el-col :span="8">
                          <span>回访情况：</span>
                          <span>
                            <span v-for="(it,idx) in item.case" :key="idx" style="margin-right: 10px;">
                              {{it}}
                            </span>
                          </span>
                        </el-col>
                        <el-col :span="8">
                          <span>其他：</span>
                          <span>{{item.case_details}}</span>
                        </el-col>
                      </div>
                    </el-row>
                    <div v-if="form.visit_log.length == 0" style="text-align: center;">
                      <el-empty description="暂无数据"></el-empty>
                    </div>
                  </div>
                </div>

                <!--通话信息-->
                <div class="ageing" style="margin-top: 20px;" id="tonghuaxinxi">
                  <div class="Datalist">
                    <div class="Datalist_l">
                      <div class="Datalist_icon"></div>
                      <div class="Datalist_text">
                        通话信息
                      </div>
                    </div>
                  </div>
                  <div class="ageing_b" style="padding: 20px;padding-top: 0;">
                    <el-tabs v-model="activeName">
                      <el-tab-pane label="客服" name="first"></el-tab-pane>
                      <el-tab-pane label="司机" name="second"></el-tab-pane>
                      <el-tab-pane label="服务商" name="third"></el-tab-pane>
                    </el-tabs>
                    <div>
                      <el-row style="display: flex;align-items: center; margin-bottom: 10px;" v-for="(item,index) in form.call_log" :key="index">
                        <div style="font-size: 16px;font-weight: 400;color: #909090;">{{item.createtime}}</div>
                        <!--<div style="font-size: 16px;font-weight: 400;color: #050505; margin-left: 20px;">吴帅丽与救援用户通话</div>-->
                        <audio
                            style="margin-left: 20px;"
                            :src="item.ossUrl"
                            controls="controls"
                            ref="audio"
                        >Your browser does not support the audio element.</audio>
                      </el-row>
                    </div>
                    <div style="text-align: center;" v-if="form.call_log.length == 0">
                      <el-empty description="暂无数据"></el-empty>
                    </div>
                  </div>
                </div>

                <!-- 照片审核信息 -->
                <div class="ageing" style="margin-top: 20px;" id="zhaopianshenhe">
                  <PhotoReviewInformation v-if="PhotoReviewInformation == true" :basics="form.basics" :odd_even_address="odd_even_address" :type="form.joinInfo.items" :data="Photoreview" :fatherdata="data" :kilometerInformation="form.kilometerInformation" @shenhe="queryshenhe"></PhotoReviewInformation>
                </div>

            <!--其他照片-->
            <div class="ageing" id="qitazhaopian">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    其他照片
                  </div>
                </div>
              </div>
              <div class="ageing_b" style="padding: 20px;">
                <div style="text-align: center;">
                  <el-empty description="暂无数据"></el-empty>
                </div>
              </div>
            </div>

                <!--派单时间轴信息-->
                <div class="ageing" style="margin-top: 20px;" id="pandanshijianzhou">
                  <div class="Datalist">
                    <div class="Datalist_l">
                      <div class="Datalist_icon"></div>
                      <div class="Datalist_text">
                        派单时间轴信息
                      </div>
                    </div>
                  </div>
                  <div class="ageing_b" style="padding: 20px;">
                    <!--时间轴的每一项-->
                    <div style="display: flex;align-items: center;margin-bottom: 60px;" v-for="(item,index) in form.timer_shaft" :key="index">
                      <div style="font-size: 16px;font-weight: 500;color: #050505;width: 150px;">{{item.event_name}}</div>
                      <div style="width: 48px;height: 48px;background: #FFECCD;border-radius: 50%;margin-left: 20px;display: flex;align-items: center;justify-content: center;">
                        <div style="width: 24px;height: 24px;background: #FF9B05;border-radius: 50%;">
                          <div class="xian" v-if="index != form.timer_shaft.length -1"></div>
                        </div>
                      </div>
                      <div style="margin-left: 20px;">
                        <div style="font-size: 14px;font-weight: 400;color: #878787;">{{item.timestamp}}</div>
                        <div>
                          <span style="font-size: 16px;font-weight: 400;color: #050505;">{{item.description}}</span>
                          <!--<span style="font-size: 16px;font-weight: 400;color: #FF9B05;margin-left: 20px;">凭证</span>-->
                        </div>
                      </div>
                    </div>
                    <div v-if="form.timer_shaft.length == 0" style="text-align: center;">
                      <el-empty description="暂无数据"></el-empty>
                    </div>
                  </div>
                </div>

                <!--工单照片-->
                <div class="ageing" style="margin-top: 20px;" id="gongdanzhaopian">
                  <div class="Datalist">
                    <div class="Datalist_l">
                      <div class="Datalist_icon"></div>
                      <div class="Datalist_text">
                        工单照片
                      </div>
                    </div>
                  </div>
                  <div class="ageing_b" style="padding: 20px;">
                    <el-row :gutter="15" v-if="form.work_order != null">
                      <el-col :span="6">
                        <div class="gongdanzhaop">
                          <img v-if="form.work_order.car_owner_signature != ''" :src="form.work_order.car_owner_signature" alt="" @click="openshowworkorder(form.work_order.car_owner_signature)">
                          <el-empty description="" v-else></el-empty>
                          <div>客户签字</div>
                        </div>
                      </el-col>
                      <!--<el-col :span="6">-->
                      <!--  <div class="gongdanzhaop">-->
                      <!--    <img v-if="form.work_order.contact_signature != ''"  :src="form.work_order.contact_signature" alt="" @click="openshowworkorder(form.work_order.contact_signature)">-->
                      <!--    <el-empty description="" v-else></el-empty>-->
                      <!--    <div>接车人签字</div>-->
                      <!--  </div>-->
                      <!--</el-col>-->
                      <el-col :span="6">
                        <div class="gongdanzhaop">
                          <img v-if="form.work_order.drivers_signature != ''"  :src="form.work_order.drivers_signature" alt="" @click="openshowworkorder(form.work_order.drivers_signature)">
                          <el-empty description="" v-else></el-empty>
                          <div>司机签名</div>
                        </div>
                      </el-col>
                      <el-col :span="6">
                        <div class="gongdanzhaop">
                          <img v-if="form.work_order.electronic_worksheets != ''"  :src="form.work_order.electronic_worksheets" alt="" @click="openshowworkorder(form.work_order.electronic_worksheets)">
                          <el-empty description="" v-else></el-empty>
                          <div>电子工单</div>
                        </div>
                      </el-col>
                    </el-row>
                    <div v-if="form.work_order == null" style="text-align: center;">
                      <el-empty description="暂无数据"></el-empty>
                    </div>
                  </div>
                </div>

              <!--<el-tab-pane label="订单备注">-->
              <!--  &lt;!&ndash;订单备注&ndash;&gt;-->
              <!--  &lt;!&ndash;<div class="ageing" style="margin-top: 20px;" id="dingdanbeizhu">&ndash;&gt;-->
              <!--  &lt;!&ndash;  <div class="Datalist">&ndash;&gt;-->
              <!--  &lt;!&ndash;    <div class="Datalist_l">&ndash;&gt;-->
              <!--  &lt;!&ndash;      <div class="Datalist_icon"></div>&ndash;&gt;-->
              <!--  &lt;!&ndash;      <div class="Datalist_text">&ndash;&gt;-->
              <!--  &lt;!&ndash;        订单备注&ndash;&gt;-->
              <!--  &lt;!&ndash;      </div>&ndash;&gt;-->
              <!--  &lt;!&ndash;      <div class="Datalist-botton" style="margin-left: 20px">&ndash;&gt;-->
              <!--  &lt;!&ndash;        <el-button class="custom-button" @click="ReplyWithRemarks()">&ndash;&gt;-->
              <!--  &lt;!&ndash;          &lt;!&ndash;el-icon-devops 是自己命名的&ndash;&gt;&ndash;&gt;-->
              <!--  &lt;!&ndash;          <i class="el-icon-tianjia" />&ndash;&gt;-->
              <!--  &lt;!&ndash;          <span style="vertical-align: middle">添加备注</span>&ndash;&gt;-->
              <!--  &lt;!&ndash;        </el-button>&ndash;&gt;-->
              <!--  &lt;!&ndash;      </div>&ndash;&gt;-->
              <!--  &lt;!&ndash;    </div>&ndash;&gt;-->
              <!--  &lt;!&ndash;    <div>&ndash;&gt;-->
              <!--  &lt;!&ndash;      <div class="custom" style="margin-left: 20px">&ndash;&gt;-->
              <!--  &lt;!&ndash;        <el-button type="primary" plain @click="ReplyWithRemarks()">回复</el-button>&ndash;&gt;-->
              <!--  &lt;!&ndash;      </div>&ndash;&gt;-->
              <!--  &lt;!&ndash;    </div>&ndash;&gt;-->
              <!--  &lt;!&ndash;  </div>&ndash;&gt;-->
              <!--  &lt;!&ndash;  <div class="ageing_b" style="padding: 20px;">&ndash;&gt;-->
              <!--  &lt;!&ndash;    <el-row>&ndash;&gt;-->
              <!--  &lt;!&ndash;      <span style="font-size: 16px;font-weight: 400;color: #2d2f47;">中华保险</span>&ndash;&gt;-->
              <!--  &lt;!&ndash;      <span style="font-size: 16px;font-weight: 400;color: #2d2f47; margin-left: 20px;">2023-07-21</span>&ndash;&gt;-->
              <!--  &lt;!&ndash;    </el-row>&ndash;&gt;-->
              <!--  &lt;!&ndash;    <el-row>&ndash;&gt;-->
              <!--  &lt;!&ndash;      <span style="font-size: 16px;font-weight: 400;color: #868792;">电瓶标准型号：QAW54a；是否启停：是；初登日期：2021-0509；是否断电拆装:否.</span>&ndash;&gt;-->
              <!--  &lt;!&ndash;    </el-row>&ndash;&gt;-->
              <!--  &lt;!&ndash;  </div>&ndash;&gt;-->
              <!--  &lt;!&ndash;</div>&ndash;&gt;-->
              <!--</el-tab-pane>-->

                <!--司机报备-->
                <div class="ageing" style="margin-top: 20px;" id="jishibaobei">
                  <div class="Datalist">
                    <div class="Datalist_l">
                      <div class="Datalist_icon"></div>
                      <!--<div class="Datalist_text" style="width: 10%;">-->
                      <div class="Datalist_text" >
                        司机报备
                      </div>
                      <div class="custom" style="margin-left: 20px">
                        <el-button class="custom-button" v-for="(item,index) in form.technician_report" :key="index" @click="Change(item,index)" style="margin: 5px;">
                          <span style="vertical-align: middle">{{item.matter}}</span>
                        </el-button>
                      </div>
                    </div>
                    <div>
                      <!--<div class="custom" style="margin-left: 20px" v-if="isShow1 == 0">-->
                      <!--  <el-button type="primary" plain @click="ReplyWithRemarks()">回复</el-button>-->
                      <!--</div>-->
                      <div class="custom" style="margin-left: 20px" v-if="technician_report.type == 'audit' && technician_report.status == 2">
                        <el-button type="danger" plain @click="bohui()">驳回</el-button>
                        <el-button type="success" plain @click="pass()">通过</el-button>
                      </div>
                    </div>
                  </div>
                  <div class="ageing_b" style="padding: 20px;">
                    <div>
                      <el-row>
                        <span style="font-size: 16px;font-weight: 400;color: #2d2f47;">{{technician_report.driver_name}}</span>
                        <span style="font-size: 16px;font-weight: 400;color: #2d2f47; margin-left: 20px;">{{technician_report.create_time}}</span>
                      </el-row>
                      <el-row>
                        <span style="font-size: 16px;font-weight: 400;color: #2d2f47;">{{technician_report.matter}}</span>
                      </el-row>
                      <el-row v-if="technician_report.matter != '订单空驶'">
                        <span style="font-size: 16px;font-weight: 400;color: #868792;">{{technician_report.content}}</span>
                      </el-row>
                      <el-row :gutter="15" v-if="technician_report.matter == '订单空驶'">
                        <el-col :span="8" v-for="(item,index) in technician_report.content.img" :key="index">
                          <img :src="item" alt="">
                        </el-col>
                      </el-row>
                    </div>
                    <div v-if="form.technician_report.length == 0" style="text-align: center;">
                      <el-empty description="暂无数据"></el-empty>
                    </div>
                  </div>
                </div>


                <!--关联订单-->
                <div class="ageing" style="margin-top: 20px;" id="guanliandingdan">
                  <AssociatedOrder v-if="showAssociatedOrder" @show="QueryshowaddAssociatedOrder" :shuju="data" :data="AssociatedOrder" :children_order="children_order" @huoqu="huoqugetDetails"></AssociatedOrder>
                </div>


                <!--跟进记录-->
                <div class="ageing" style="margin-top: 20px;" id="genjinjilu">
                  <div class="Datalist">
                    <div class="Datalist_l">
                      <div class="Datalist_icon"></div>
                      <div class="Datalist_text">
                        跟进记录
                      </div>
                      <!--<div class="custom" style="margin-left: 20px">-->
                      <!--  <el-button class="custom-button" style="background: #FF9B05 !important;border-color: #FF9B05!important;" @click="AddFollowUp()" v-auths="[`${$config.uniquePrefix}orderModule:info:followup`]">-->
                      <!--    <i class="el-icon-tianjia" />-->
                      <!--    <span style="vertical-align: middle">添加</span>-->
                      <!--  </el-button>-->
                      <!--</div>-->
                    </div>
                  </div>
                  <div class="ageing_b" style="padding: 20px;">
                    <div v-for="(item,index) in form.follow_record" :key="index" style="margin-bottom: 20px;">
                      <el-row>
                        <div style="font-size: 16px;font-weight: 400;color: #050505;">
                          <span>{{item.time}}</span>
                          <span style="margin-left: 20px;">【{{item.follow_name}}】</span>
                        </div>
                      </el-row>
                      <el-row>
                        <span style="font-size: 16px;font-weight: 400;color: #868792;">{{item.remark}}</span>
                      </el-row>
                    </div>
                    <div v-if="form.follow_record.length == 0" style="text-align: center;">
                      <el-empty description="暂无数据"></el-empty>
                    </div>

                  </div>
                </div>


                <!--短信电话记录-->
                <div class="ageing" style="margin-top: 20px;" id="duanxindianhu">
                  <div class="Datalist">
                    <div class="Datalist_l">
                      <div class="Datalist_icon"></div>
                      <div class="Datalist_text">
                        短信电话记录
                      </div>
                    </div>
                  </div>
                  <div class="ageing_b" style="padding: 20px;">
                    <el-empty description="暂无数据"></el-empty>
                  </div>
                </div>


                <!--客户评价-->
                <div class="ageing" style="margin-top: 20px;" id="chezhupingjia">
                  <div class="Datalist">
                    <div class="Datalist_l">
                      <div class="Datalist_icon"></div>
                      <div class="Datalist_text">
                        客户评价
                      </div>
                    </div>
                  </div>
                  <div class="ageing_b" style="padding: 20px;">
                    <div v-for="(item,index) in form.positive_comment" :key="index">
                      <el-row>
                        <div style="font-size: 16px;font-weight: 400;color: #050505; display: flex;">
                          <span>{{item.create_time}}</span>
                          <span style="margin-left: 20px;">{{item.nickname}}</span>
                          <span style="margin-left: 20px;">
                    <img style="width: 20px;height: 19px; margin-left: 5px;" src="@/assets/images/home/order/pingjia.png" alt="" v-for="(it) in item.star_number">
                  </span>
                        </div>
                      </el-row>
                      <el-row style="margin-top: 20px;">
                        <span style="font-size: 16px;font-weight: 400;color: #868792;">{{item.details}}</span>
                      </el-row>
                    </div>
                    <div style="text-align: center;" v-if="form.positive_comment.length == 0">
                      <el-empty description="暂无数据"></el-empty>
                    </div>
                  </div>
                </div>

                <!--投诉-->
                <div class="ageing" style="margin-top: 20px;" id="tousu">
                  <div class="Datalist">
                    <div class="Datalist_l">
                      <div class="Datalist_icon"></div>
                      <div class="Datalist_text">
                        投诉记录
                      </div>
                      <div class="custom" style="margin-left: 20px">
                        <!--<el-button class="custom-button" style="background: #BBB6AC !important;border-color: #BBB6AC!important;" @click="Tousu"  v-auths="[`${$config.uniquePrefix}orderModule:info:complaint`]">-->
                        <!--  &lt;!&ndash;el-icon-devops 是自己命名的&ndash;&gt;-->
                        <!--  <i class="el-icon-tianjia" />-->
                        <!--  <span style="vertical-align: middle">添加投诉</span>-->
                        <!--</el-button>-->
                      </div>
                    </div>
                  </div>
                  <div class="ageing_b" style="padding: 20px;">
                    <div v-for="(item,index) in form.complaint" :key="index" style="margin-bottom: 20px;">
                      <el-row>
                        <div style="font-size: 16px;font-weight: 400;color: #050505; display: flex;">
                          <span>{{item.time}}</span>
                          <span style="margin-left: 20px;">【{{item.facilitator_name}}】</span>
                          <span style="margin-left: 20px;">
                      <!--<img style="width: 20px;height: 19px; margin-left: 5px;" src="@/assets/images/home/order/pingjia.png" alt="" v-for="(it) in item.star_number">-->
                    </span>
                        </div>
                      </el-row>
                      <el-row style="margin-top: 10px;">
                        <span style="font-size: 16px;font-weight: 400;color: #868792;">{{item.reason}}</span>
                      </el-row>
                      <el-row>
                        <span style="font-size: 16px;font-weight: 400;color: #868792;">{{item.details}}</span>
                      </el-row>
                    </div>
                    <div style="text-align: center;" v-if="form.complaint.length == 0">
                      <el-empty description="暂无数据"></el-empty>
                    </div>

                  </div>
                </div>


                <!--催单-->
                <div class="ageing" style="margin-top: 20px;" id="cuidan">
                  <div class="Datalist">
                    <div class="Datalist_l">
                      <div class="Datalist_icon"></div>
                      <div class="Datalist_text">
                        催单记录
                      </div>
                      <div class="custom" style="margin-left: 20px">
                        <!--<el-button class="custom-button" style="background: #F3505B !important;border-color: #F3505B!important;" @click="cuidan" v-auths="[`${$config.uniquePrefix}orderModule:info:reminder`]">-->
                        <!--  &lt;!&ndash;el-icon-devops 是自己命名的&ndash;&gt;-->
                        <!--  <i class="el-icon-cuidan" />-->
                        <!--  <span style="vertical-align: middle">催单</span>-->
                        <!--</el-button>-->
                      </div>
                    </div>
                  </div>
                  <div class="ageing_b" style="padding: 20px;">
                    <div v-for="(item,index) in form.reminder_log" :key="index" style="margin-bottom: 20px;">
                      <el-row>
                        <div style="font-size: 16px;font-weight: 400;color: #050505; display: flex;">
                          <span>{{item.time}}</span>
                          <span style="margin-left: 20px;">【{{item.operator_name}}】</span>
                          <span style="margin-left: 20px;">
                      <!--<img style="width: 20px;height: 19px; margin-left: 5px;" src="@/assets/images/home/order/pingjia.png" alt="">-->
                            <!--<img style="width: 20px;height: 19px; margin-left: 5px;" src="@/assets/images/home/order/pingjia.png" alt="">-->
                            <!--<img style="width: 20px;height: 19px; margin-left: 5px;" src="@/assets/images/home/order/pingjia.png" alt="">-->
                            <!--<img style="width: 20px;height: 19px; margin-left: 5px;" src="@/assets/images/home/order/pingjia.png" alt="">-->
                            <!--<img style="width: 20px;height: 19px; margin-left: 5px;" src="@/assets/images/home/order/pingjia.png" alt="">-->
                    </span>
                        </div>
                      </el-row>
                      <el-row style="margin-top: 10px;">
                        <span style="font-size: 16px;font-weight: 400;color: #868792;">{{item.details}}</span>
                        <!--<el-button type="primary" style="margin-left: 10px;" plain @click="ReplyWithRemarks(item)">回复</el-button>-->
                      </el-row>
                    </div>
                    <div style="text-align: center;" v-if="form.reminder_log.length == 0">
                      <el-empty description="暂无数据"></el-empty>
                    </div>
                  </div>
                </div>


                <!--修改记录-->
                <div class="ageing" style="margin-top: 20px;" id="xiugaijilu">
                  <div class="Datalist">
                    <div class="Datalist_l">
                      <div class="Datalist_icon"></div>
                      <div class="Datalist_text">
                        修改记录
                      </div>
                    </div>
                  </div>
                  <div class="ageing_b" style="padding: 20px;">
                    <div v-for="(item,index) in form.update_log" :key="index">
                      <el-row :gutter="15">
                        <el-col :span="4">
                          <div style="font-size: 16px;font-weight: 400;color: #868792;">{{item.time}} </div>
                        </el-col>
                        <el-col :span="20">
                          <div style="font-size: 16px;font-weight: 400;color: #050505;">
                            <span>{{item.operator_name}}</span>
                            <span style="color: rgb(161 163 167); margin-left: 10px;margin-right: 10px;">修改了</span>
                            <!--<span>申请救援地</span>-->
                            <span>【{{item.fields_mag}}】</span>
                            <span>由</span>
                            <span>【{{item.source_value}}】</span>
                            <span style="color: rgb(161 163 167); margin-left: 10px;margin-right: 10px;">修改为</span>
                            <span>【{{item.amend_value}}】</span>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                    <div style="text-align: center;" v-if="form.update_log.length == 0">
                      <el-empty description="暂无数据"></el-empty>
                    </div>
                  </div>
                </div>

          <!--</div>-->
          </el-card>

          <!--&lt;!&ndash; 功能主体 &ndash;&gt;-->
          <!--<el-card shadow="never">-->
          <!--  &lt;!&ndash;回访信息&ndash;&gt;-->
          <!--  &lt;!&ndash;<div class="ageing" style="margin-top: 20px;" id="jishibaobei">&ndash;&gt;-->
          <!--  &lt;!&ndash;  <div class="Datalist">&ndash;&gt;-->
          <!--  &lt;!&ndash;    <div class="Datalist_l">&ndash;&gt;-->
          <!--  &lt;!&ndash;      <div class="Datalist_icon"></div>&ndash;&gt;-->
          <!--  &lt;!&ndash;      <div class="Datalist_text">&ndash;&gt;-->
          <!--  &lt;!&ndash;        回访信息&ndash;&gt;-->
          <!--  &lt;!&ndash;      </div>&ndash;&gt;-->
          <!--  &lt;!&ndash;      <div class="custom" style="margin-left: 20px">&ndash;&gt;-->
          <!--  &lt;!&ndash;        <el-button class="custom-button" style="background: #FF9B05 !important;border-color: #FF9B05!important;" @click="huifang()">&ndash;&gt;-->
          <!--  &lt;!&ndash;          &lt;!&ndash;el-icon-devops 是自己命名的&ndash;&gt;&ndash;&gt;-->
          <!--  &lt;!&ndash;          <i class="el-icon-tianjia" />&ndash;&gt;-->
          <!--  &lt;!&ndash;          <span style="vertical-align: middle">添加</span>&ndash;&gt;-->
          <!--  &lt;!&ndash;        </el-button>&ndash;&gt;-->
          <!--  &lt;!&ndash;      </div>&ndash;&gt;-->
          <!--  &lt;!&ndash;    </div>&ndash;&gt;-->
          <!--  &lt;!&ndash;  </div>&ndash;&gt;-->
          <!--  &lt;!&ndash;  <div class="ageing_b" style="padding: 20px;">&ndash;&gt;-->
          <!--  &lt;!&ndash;    <el-row style="display: flex;align-items: center; margin-bottom: 10px;">&ndash;&gt;-->
          <!--  &lt;!&ndash;      <div style="font-size: 16px;font-weight: 400;color: #909090;">2023-05-0611:57:54</div>&ndash;&gt;-->
          <!--  &lt;!&ndash;      <div style="font-size: 16px;font-weight: 400;color: #050505; margin-left: 20px;">吴帅丽与救援用户通话</div>&ndash;&gt;-->
          <!--  &lt;!&ndash;      <audio&ndash;&gt;-->
          <!--  &lt;!&ndash;          style="margin-left: 20px;"&ndash;&gt;-->
          <!--  &lt;!&ndash;          :src="src"&ndash;&gt;-->
          <!--  &lt;!&ndash;          autoplay="autoplay"&ndash;&gt;-->
          <!--  &lt;!&ndash;          controls="controls"&ndash;&gt;-->
          <!--  &lt;!&ndash;          ref="audio"&ndash;&gt;-->
          <!--  &lt;!&ndash;      >Your browser does not support the audio element.</audio>&ndash;&gt;-->
          <!--  &lt;!&ndash;    </el-row>&ndash;&gt;-->
          <!--  &lt;!&ndash;    <el-row style="display: flex;align-items: center; margin-bottom: 10px;">&ndash;&gt;-->
          <!--  &lt;!&ndash;      <div style="font-size: 16px;font-weight: 400;color: #909090;">2023-05-0611:57:54</div>&ndash;&gt;-->
          <!--  &lt;!&ndash;      <div style="font-size: 16px;font-weight: 400;color: #050505; margin-left: 20px;">吴帅丽与救援用户通话</div>&ndash;&gt;-->
          <!--  &lt;!&ndash;      <audio&ndash;&gt;-->
          <!--  &lt;!&ndash;          style="margin-left: 20px;"&ndash;&gt;-->
          <!--  &lt;!&ndash;          :src="src"&ndash;&gt;-->
          <!--  &lt;!&ndash;          autoplay="autoplay"&ndash;&gt;-->
          <!--  &lt;!&ndash;          controls="controls"&ndash;&gt;-->
          <!--  &lt;!&ndash;          ref="audio"&ndash;&gt;-->
          <!--  &lt;!&ndash;      >Your browser does not support the audio element.</audio>&ndash;&gt;-->
          <!--  &lt;!&ndash;    </el-row>&ndash;&gt;-->
          <!--  &lt;!&ndash;    <el-row style="display: flex;align-items: center;">&ndash;&gt;-->
          <!--  &lt;!&ndash;      <div style="font-size: 16px;font-weight: 400;color: #909090;">2023-05-0611:57:54</div>&ndash;&gt;-->
          <!--  &lt;!&ndash;      <div style="font-size: 16px;font-weight: 400;color: #050505; margin-left: 20px;">机器人客服催促服务商派单，已接通18秒</div>&ndash;&gt;-->
          <!--  &lt;!&ndash;    </el-row>&ndash;&gt;-->
          <!--  &lt;!&ndash;  </div>&ndash;&gt;-->
          <!--  &lt;!&ndash;</div>&ndash;&gt;-->
          <!--</el-card>-->
        </el-form>

        <!--按钮-->
        <!--<div class="card_bottom">-->
        <!--  <el-button style="width: 200px !important;">取消</el-button>-->
        <!--  <el-button type="warning" style="width: 200px !important; margin-left: 20px;">提交</el-button>-->
        <!--</div>-->
      </div>

      <!--对账信息 模块-->
      <div  v-if='isShow == 1'>
        <div>
          <ReconciliationInformation :data="data" :dict="dict"></ReconciliationInformation>
        </div>
      </div>

      <!--订单消息 模块-->
      <div  v-if='isShow == 2'>
        <div>
          <OrderMessage></OrderMessage>
        </div>
      </div>


      <!-- 报备弹窗 -->
      <reporting-edit v-if="showreporting === true" :data="reportingcurrent" :visible.sync="showreporting" @done="getDetails"/>

      <!-- 订单审核弹窗 -->
      <orderReview-edit v-if="showorderReview === true" :data="orderReviewcurrent" :visible.sync="showorderReview" />

      <!-- 订单复审弹窗 -->
      <toReviewACase-edit v-else-if="showtoReviewACase === true" :data="toReviewACasecurrent" :visible.sync="showtoReviewACase" />

      <!--改派弹窗-->
      <reassignment-edit v-if="showreassignment === true" :dict="dict" :data="reassignmentcurrent" :visible.sync="showreassignment" :repairshop="repairshop" @freshenMap="freshenatlas"/>

      <!-- 添加跟进弹窗 -->
      <addFollowUp-edit v-if="showaddFollowUp === true" :data="addFollowUpcurrent" :visible.sync="showaddFollowUp" @done="getDetails"/>

      <!--关联订单弹窗-->
      <AssociatedOrderEdit v-if="showaddAssociatedOrder == true" :data="addAssociatedOrdercurrent" :visible.sync="showaddAssociatedOrder" @showAdd="showadd" @showName="showname" @shuaxin="renovation"/>

      <!--订单收回弹窗-->
      <orderRecovery-edit :data="orderRecoverycurrent" :visible.sync="showorderRecovery"/>

      <!--添加回访弹窗-->
      <addReturnVisit-edit v-if="showaddReturnVisit === true" :dict="dict" :data="addReturnVisitcurrent" :visible.sync="showaddReturnVisit" @done="getDetails"/>

      <!--客户话术弹窗-->
      <customerScript-edit v-if="showcustomerScriptEdit === true" :data="customerScriptEditcurrent" :visible.sync="showcustomerScriptEdit"/>

      <!--备注回复-->
      <replyWithRemarks-edit v-if="showreplyWithRemarksEdit == true" :item="item" :data="replyWithRemarksEditcurrent" :visible.sync="showreplyWithRemarksEdit" @done="getDetails"/>

      <!--报备驳回-->
      <reasonForRejectionOfReport-edit v-if="showreasonForRejectionOfReport === true" :id="id" :data="reasonForRejectionOfReportcurrent" :visible.sync="showreasonForRejectionOfReport"/>

      <!--添加回访-->
      <returnVisit-edit v-if="showreturnVisit === true" :data="currentreturnVisit" :dict="dict" :visible.sync="showreturnVisit"/>

      <!--催单-->
      <reminder-edit v-if="showreminder == true" :data="currentreminder" :visible.sync="showreminder" @done="getDetails"/>

      <!--投诉-->
      <complaint-edit v-if="showcomplaint == true" :data="currentcomplaint" :visible.sync="showcomplaint" @done="getDetails"/>

      <!--外协-->
      <coordination-edit :data="currentcoordination" :visible.sync="showcoordination"/>

      <!--取消-->
      <cancel-edit v-if="showcancel == true" :data="canceldata" :visible.sync="showcancel" @done="getDetails" @fanhui="getfanhui"/>

      <!--空驶-->
      <empty-edit v-if="showempty == true" :data="emptydata" :visible.sync="showempty" @done="getDetails"/>

      <!--收款信息-->
      <collection-edit v-if="shoukuanedit == true" :data="emptydata" :visible.sync="shoukuanedit" @done="getDetails"/>

      <!--标记-->
      <el-dialog
          center
          title="标记"
          :visible.sync="dialogVisible"
          width="30%"
          :before-close="handleClose">
      <span>
        <el-button type="primary" plain @click="Tousu">投诉订单</el-button>
        <el-button type="primary" plain @click="waixie">外协订单</el-button>
      </span>
        <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
      </el-dialog>

      <el-dialog
          center
          v-if="selectaddress == true"
          title="选择地址"
          :visible.sync="selectaddress"
          width="40%"
          :before-close="handleClose">
        <div>
          <!--腾讯地图选点组件-->
          <!--<div style="display: flex;">-->
          <!--  <el-input clearable v-model="keywords" placeholder="请输入位置"/>-->
          <!--  <el-button style="margin-left: 10px;" @click="onSearch">搜索</el-button>-->
          <!--</div>-->
          <!--<iframe id="mapPage" width="100%" height="700px;" frameborder="0" v-bind:src="keyUrl"></iframe>-->
          <!--自定义高德选点组件-->
          <AMapLoadersiteselection v-if="showAMapLoadersiteselection == true" :data="origin" :mark="mark" @location="getlocation"/>
        </div>
        <div slot="footer">
          <!-- <el-button
           @click="selectaddress = false">取消
          </el-button> -->
          <!-- <el-button
              style="margin-left: 20px;"
              type="primary"
              @click="selectaddress = false">确定
          </el-button> -->
        </div>
      </el-dialog>

      <el-dialog
        center
        v-if="showqiangdan == true"
        title="抢单"
        :visible.sync="showqiangdan"
        width="30%"
        :before-close="handleCloseshowqiangdan">
        <div>
          <el-input v-model="price" placeholder="请填写抢单价格"></el-input>
        </div>
        <div slot="footer">
          <!--<el-button-->
          <!--  @click="dialogVisible = false">取消-->
          <!--</el-button>-->
          <el-button
            style="margin-left: 20px;"
            type="primary"
            @click="qiangdansave">确定
          </el-button>
        </div>
      </el-dialog>


      <el-dialog
        center
        title="工单照片"
        :visible.sync="showworkorder"
        width="50%"
        :before-close="handleCloseworkorder">
        <div>
          <img :src="workorder" style="width: 100%;height: 100%;">
        </div>
        <div slot="footer">
          <!--<el-button-->
          <!--  @click="dialogVisible = false">取消-->
          <!--</el-button>-->
          <!--<el-button-->
          <!--  style="margin-left: 20px;"-->
          <!--  type="primary"-->
          <!--  @click="qiangdansave">确定-->
          <!--</el-button>-->
        </div>
      </el-dialog>

    </div>

    <!--新建订单-->
    <order-edit v-if="showEdit == true" @show="ShowOrder" :name="orderName" :data="content" :dict="dict"></order-edit>

  </div>
</template>

<script>
//获取高德地图的key
import setting from '@/config/config'

// 自定义高德组件
import AMapLoader from "@/components/AMapLoader/index.vue"
//引入自定义高德地图选点组件
import AMapLoadersiteselection from '@/components/AMapLoadersiteselection/index.vue'

//引入照片审核信息模块
import PhotoReviewInformation from './PhotoReviewInformation.vue'
//引入关联订单模块
import AssociatedOrder from './AssociatedOrder.vue'

//引入对账信息模块
import ReconciliationInformation from './ReconciliationInformation.vue'
//引入订单消息模块
import OrderMessage from './OrderMessage.vue'

// 引入报备弹窗
import ReportingEdit from './components/reporting-edit.vue';
//引入订单审核弹窗
import OrderReviewEdit from '../toBeReviewed/components/orderReview-edit.vue'
// 引入订单复审弹窗
import ToReviewACaseEdit from './components/toReviewACase-edit.vue'
// 引入改派弹窗
// import ReassignmentEdit from './components/reassignment-edit.vue'
import ReassignmentEdit from '../toBeDispatched/components/reassignment-edit.vue'
// 引入添加跟进弹窗
import AddFollowUpEdit from './components/addFollowUp-edit.vue'
//引入关联订单弹窗
import AssociatedOrderEdit from './components/addAssociatedOrder-edit.vue'
//引入订单收回弹窗
import OrderRecoveryEdit from './components/orderRecovery-edit.vue'
//引入添加回访弹窗
import AddReturnVisitEdit from '../pendingFollowUp/components/addReturnVisit-edit.vue'
//引入客户话术弹窗
import CustomerScriptEdit from './components/customerScript-edit.vue'
//引入备注回复弹窗
import ReplyWithRemarksEdit from './components/replyWithRemarks-edit.vue'
//引入报备驳回原因
import ReasonForRejectionOfReportEdit from './components/reasonForRejectionOfReport-edit.vue'
//引入添加回访
// import ReturnVisitEdit from './components/returnVisit-edit.vue'
//引入催单
import ReminderEdit from './components/reminder-edit.vue'
//引入投诉
import ComplaintEdit from './components/complaint-edit.vue'
//引入外协
import CoordinationEdit from './components/coordination-edit.vue'
//引入取消
import CancelEdit from './components/cancel-edit.vue'
//引入空驶
import EmptyEdit from './components/empty-edit.vue'
//引入创建订单
import OrderEdit from './add.vue'
//引入收款信息编辑
import CollectionEdit from './components/collection-edit.vue'

// 引入的接口
import {
  orderDetails,
  Editupdate_basics,
  Editupdate_join_info,
  Savereport_audit,
  getrescue_type,
  details_kilometre,
  order_rob
} from "@/api/orderModule";
//服务项目接口
import {getget_services_available} from '@/api/yunli'

// 引入的接口
import {get_tel_no,} from '@/api/phone'

//引入复制
import Vue from "vue";
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

export default {
  name: 'index',
  // 支柱道具属性
  props: {
    //数据
    data: Object,
    //字典
    dict: Object
  },
  // 组件生效
  components: {
    PhotoReviewInformation,
    AssociatedOrder,
    ReconciliationInformation,
    OrderMessage,
    ReportingEdit,
    OrderReviewEdit,
    ToReviewACaseEdit,
    ReassignmentEdit,
    AddFollowUpEdit,
    AssociatedOrderEdit,
    OrderRecoveryEdit,
    AddReturnVisitEdit,
    CustomerScriptEdit,
    ReplyWithRemarksEdit,
    ReasonForRejectionOfReportEdit,
    // ReturnVisitEdit,
    ReminderEdit,
    ComplaintEdit,
    CoordinationEdit,
    OrderEdit,
    AMapLoader,
    AMapLoadersiteselection,
    CancelEdit,
    EmptyEdit,
    CollectionEdit
  },
  // Data数据
  data() {
    return {
      form:{
        basics:{},
        joinInfo:{},
        settlementInformation:{},
        facilitatorSettlementInformation:{},
      },

      isShow:0,
      activeList:[
        {
          img: require('../../../assets/images/home/custom/icon1active.png'),
          name:'基本信息',
        },
        {
          img: require('../../../assets/images/home/custom/icon2.png'),
          name:'对账信息',
        },
        // {
        //   img: require('../../../assets/images/home/custom/icon3.png'),
        //   name:'订单消息',
        // },
      ],

      // 注意事项字段
      textarea:'',

      src: "",

      //报备数据
      reportingcurrent:null,
      //报备弹窗
      showreporting:false,

      //订单审核
      orderReviewcurrent:null,
      //审核弹窗
      showorderReview:false,

      //订单复审
      toReviewACasecurrent:null,
      showtoReviewACase:false,

      //改派
      reassignmentcurrent:null,
      showreassignment:false,

      //添加跟进
      addFollowUpcurrent:null,
      showaddFollowUp:false,

      //关联订单
      showAssociatedOrder:true,
      addAssociatedOrdercurrent:null,
      showaddAssociatedOrder:false,

      //订单收回
      orderRecoverycurrent:null,
      showorderRecovery:false,

      //添加回访
      addReturnVisitcurrent:null,
      showaddReturnVisit:false,

      //客户话术
      customerScriptEditcurrent:null,
      showcustomerScriptEdit:false,

      //备注回复
      replyWithRemarksEditcurrent:null,
      showreplyWithRemarksEdit:false,
      item:null,

      //报备驳回原因
      reasonForRejectionOfReportcurrent:null,
      showreasonForRejectionOfReport:false,

      //添加回访
      currentreturnVisit:null,
      showreturnVisit:false,

      //催单
      currentreminder:null,
      showreminder:false,

      //投诉
      currentcomplaint:null,
      showcomplaint:false,

      //外协
      currentcoordination:null,
      showcoordination:false,

      //取消
      showcancel:false,
      canceldata:null,

      //空驶
      showempty:false,
      emptydata:null,

      //基本信息编辑状态
      isEdit:false,

      //受理信息编辑状态
      orderReceivingisEdit:false,

      //订单结算信息
      orderReceivingisEdit1:false,

      // 司机接单信息编辑
      sijiedit:false,

      //收款信息编辑
      shoukuanedit:false,

      //结算信息编辑状态
      settlementEdit:false,

      //地图组件延时加载
      lazyLoading:true,
      //高度
      msgData:0,
      currentComponent: 'AMapLoader', // 初始显示 MyComponent 组件

      reportingList:[
        {
          label:'报备1',
          id:1,
        },
        {
          label:'报备2',
          id:2,
        },
        {
          label:'报备3',
          id:3,
        }
      ],
      isShow1:0,

      //标记
      dialogVisible: false,

      //用户传递的经度纬度
      locationorigin:[],
      locationdestination:[],

      //选择地址
      selectaddress:false,
      //iframe
      keyUrl:'',
      showAMapLoadersiteselection:false,

      //服务项目
      ServicesAvailable:[],

      //照片审核信息
      Photoreview:[],
      //照片审核信息
      PhotoReviewInformation:false,

      //关联订单
      AssociatedOrder:[],
      //子订单
      children_order: [],

      //报备
      technician_report:{},
      id:0,

      //创建订单
      showEdit:false,
      orderName:'',
      content:null,

      // 规划
      project:'apply',
      // 实际规划
      coord:[],

      //抢单弹窗
      showqiangdan:false,
      // 抢单价格
      price:'',

      //工单照片
      workorder:'',
      showworkorder:false,

      //计价方式
      disabled:false,

      //单双地址
      odd_even_address:'',

      // 计价标准
      standard:'',

      // 用于修改地址
      mark:[],

      activeName:'first',

      //是否显示附近修理厂
      repairshop:true

    }
  },
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    // 调用获取详情接口
    this.getDetails();

    // 获取申请规划还是实际规划路线
    this.getGongli();

    //获取地图左侧内容的高度 传值给地图子组件
    // this.msgData = this.$refs.element.offsetHeight - 20;  //减去的20是内边距距离
    this.msgData = 300 - 20;  //减去的20是内边距距离

    //加载照片审核信息组件
    this.PhotoReviewInformation = true;

    // 高德地图
    window._AMapSecurityConfig = {
      securityJsCode: setting.secretkey,
    }

    //加载地图组件
    // this.lazyLoading = true;


    // 腾讯地图组件监听
    window.addEventListener(
      'message',
      function (event) {
        // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
        var loc = event.data;
        if (loc && loc.module === 'locationPicker') {
          // 防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
          window.parent.selectAdderss(loc);
        }
      },
      false,
    );
    window.selectAdderss = this.selectAdderss;

    // 获取服务项目
    // this.getFuwuxiangmu();
  },
  // 方法集合
  methods: {
    //点击tab
    handleClick(tab, event){
      this.getDetails();
    },

    // 点击复制用户H5链接
    onCopy(){
      this.$copyText(this.form.h5_order_url).then(() => {
        // alert('复制成功')
        this.$message.success('复制成功！')
      }, () => {
        // alert('复制失败')
        this.$message.error('复制失败！')
      })
    },

    // 点击复制外协链接
    onCopy1(){
      this.$copyText(this.form.h5_outsource_url).then(() => {
        // alert('复制成功')
        this.$message.success('复制成功！')
      }, () => {
        // alert('复制失败')
        this.$message.error('复制失败！')
      })
    },

    //获取详情
    getDetails(){
      let where = {
        odd: this.data.order_id
      }
      orderDetails(this.data.id,where).then(res => {
        console.log(res);
        this.form = res.data;
        this.form.basics = res.data.basics;
        this.form.joinInfo = res.data.joinInfo;
        this.form.settlementInformation = res.data.settlementInformation;
        this.form.facilitatorSettlementInformation = res.data.facilitatorSettlementInformation;
        // 照片审核
        this.Photoreview = res.data.photo_review_information;
        // 刷新下照片审核组件
        this.PhotoReviewInformation = false;
        this.$nextTick(() => {
          this.PhotoReviewInformation = true;
        })

        // 关联订单
        this.AssociatedOrder = res.data.correlation_order;
        // 子订单
        this.children_order = res.data.children_order;

        if(res.data.technician_report.length != 0){
          //报备默认显示
          this.technician_report = res.data.technician_report[0];
        }

        //赋值当前订单 行车起点
        // if(this.form.kilometerInformation.starting_point_coord.length > 0){
        //   this.locationorigin[0] = this.form.kilometerInformation.starting_point_coord[0];
        //   this.locationorigin[1] = this.form.kilometerInformation.starting_point_coord[1];
        // }else {
        //   this.locationorigin = [];
        // }
        // // 行车终点
        // this.locationdestination[0] = this.form.kilometerInformation.destination_coord[0];
        // this.locationdestination[1] = this.form.kilometerInformation.destination_coord[1];

        // 管理订单
        this.showAssociatedOrder = false;
        this.$nextTick(() => {
          this.showAssociatedOrder = true;
        })

        //计价方式是否可以修改
        if(this.form.order_status == 6 && (this.form.check_status == 0 || this.form.check_status == 3) ){
          this.disabled = false;
        }else {
          this.disabled = true;
        }

        //计价标准
        if(parseFloat(this.form.facilitatorSettlementInformation.bargain_price) > 0 || this.form.facilitatorSettlementInformation.bargain_price == ''){
          this.standard = '否'
        }else{
          this.standard = '是'
        }

        //单双地址
        this.odd_even_address = this.form.odd_even_address


        //获取服务项目
        this.getFuwuxiangmu(this.form.channel_id);

        this.$forceUpdate();
      })
    },

    //获取订单公里信息详情接口
    getGongli(){
      let where = {
        odd: this.data.order_id,
        plan: this.project
      }
      details_kilometre(this.data.id,where).then(res => {
        console.log(res)
        if(res.code == 200){
          // 申请路线 实际路线
          this.coord = res.data;
          // 刷新下地图组件
          this.lazyLoading = false;
          this.$nextTick(() => {
            this.lazyLoading = true;
          })
        }else {
         this.$message.error(res.data.msg);
        }
      })
    },
    //选择申请规划或者实际规划
    selectproject(value){
      console.log(value);
      // 是申请规划还是实际规划
      if(value == 'practical'){
        // 实际规划
        this.project = 'practical'
        this.getGongli();
      }else {
        // 申请规划
        this.practical = 'apply'
        this.getGongli();
      }
    },

    //获取服务项目
    getFuwuxiangmu(value){
      // getget_services_available().then(res => {
      //   this.ServicesAvailable = res.data;
      // })
      getrescue_type(value).then(res => {
        if(res.code == 200){
          this.ServicesAvailable = res.data;
        }else {
          this.$message.error(res.msg);
        }
      })
    },

    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    // 选项卡点击事件
    xuanxiangclick(item,index){
      console.log(item);
      console.log(index);
      this.isShow = index;
      if(item.name == '基本信息'){
        this.activeList[0].img = require('../../../assets/images/home/custom/icon1active.png');
        this.activeList[1].img = require('../../../assets/images/home/custom/icon2.png');
        // this.activeList[2].img = require('../../../assets/images/home/custom/icon3.png');
      }else if(item.name == '对账信息'){
        this.activeList[0].img = require('../../../assets/images/home/custom/icon1.png');
        this.activeList[1].img = require('../../../assets/images/home/custom/icon2active.png');
        // this.activeList[2].img = require('../../../assets/images/home/custom/icon3.png');
      }else if(item.name == '订单消息'){
        this.activeList[0].img = require('../../../assets/images/home/custom/icon1.png');
        this.activeList[1].img = require('../../../assets/images/home/custom/icon2.png');
        // this.activeList[2].img = require('../../../assets/images/home/custom/icon3active.png');
      }
    },

    // 返回按钮
    returnPage(){
      this.$emit('showDetalis',false) ;
    },

    // 播放组件
    // handlePlay(row){
    //   this.src = row.filePath;
    //   this.play();
    // },
    // 播放
    play(){
      this.$refs.audio.play();
    },
    // 音频暂停
    stop(){
      this.$refs.audio.pause();
      this.$refs.audio.currentTime = 0;
    },

    // 锚点跳转连接 平滑过度效果
    goAnchor(selector){
      console.log(selector)
      this.$el.querySelector(selector).scrollIntoView({
        behavior: "smooth",  // 平滑过渡
        block:    "start"  // 上边框与视窗顶部平齐。默认值
      });
    },

    //点击报备按钮
    Reporting(){
      this.reportingcurrent = this.data;
      this.showreporting = true;
    },

    //点击审核按钮
    ToExamine(){
      this.orderReviewcurrent = this.data;
      this.showorderReview = true;
    },

    //点击复审按钮
    ToReviewACase(){
      this.toReviewACasecurrent = this.data;
      this.showtoReviewACase = true;
    },

    //点击改派按钮
    Reassignment(){
      this.reassignmentcurrent = this.data;
      this.showreassignment = true;
    },
    //接收改派组件传递的数据
    freshenatlas(){
      // 刷新下地图组件
      this.lazyLoading = false;
      this.$nextTick(() => {
        this.lazyLoading = true;
      })
    },

    //添加跟进按钮
    AddFollowUp(){
      this.showaddFollowUp = true;
      this.addFollowUpcurrent = this.data;
    },

    //关联订单子组件传递的值
    QueryshowaddAssociatedOrder(val){
      console.log(val)
      this.showaddAssociatedOrder = true;
      this.addAssociatedOrdercurrent = this.data;
      this.getDetails();
      this.$forceUpdate();
    },

    //订单收回
    TakeBack(){
      this.showorderRecovery = true;
    },

    //回访按钮
    ReturnVisit(){
      console.log(this.data);
      console.log(this.dict);
      let that = this;
      that.addReturnVisitcurrent = this.data;
      console.log(that.addReturnVisitcurrent);
      this.showaddReturnVisit = true;
    },

    //客户话术弹窗
    CustomerScript(){
      this.showcustomerScriptEdit = true;
      this.customerScriptEditcurrent = this.data;
    },

    //备注回复
    ReplyWithRemarks(item){
      this.showreplyWithRemarksEdit = true;
      this.replyWithRemarksEditcurrent = this.data;
      this.item = item;
    },


    //点击基本信息编辑按钮
    Edit(){
      this.isEdit = true;
    },
    //基本信息确认按钮
    infoConfirm(){
      this.isEdit = false;
      Editupdate_basics(this.data.id,this.form.basics).then(res => {
        console.log(res);
        if(res.code == 200){
          this.$message.success(res.msg);
          this.isEdit = false;
          this.getDetails();
        }else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },
    //基本信息取消按钮
    infoCancel(){
      this.isEdit = false;
    },

    //点解受理信息的编辑
    orderReceiving(){
      this.orderReceivingisEdit = true;
    },
    //确认
    orderReceivingConfirm(){
      this.orderReceivingisEdit = false;
      Editupdate_join_info(this.data.id,this.form.joinInfo).then(res => {
        console.log(res);
        if(res.code == 200){
          this.$message.success(res.msg);
          this.orderReceivingisEdit = false;
          this.getDetails();
        }else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },
    //取消
    orderReceivingCancel(){
      this.orderReceivingisEdit = false;
      this.getDetails();
    },

    //点击订单结算信息编辑
    orderReceiving1(){
      this.orderReceivingisEdit1 = true;
    },
    //确认
    orderReceivingConfirm1(){
      this.orderReceivingisEdit = false;
      Editupdate_join_info(this.data.id,this.form.joinInfo).then(res => {
        console.log(res);
        if(res.code == 200){
          this.$message.success(res.msg);
          this.orderReceivingisEdit1 = false;
          this.getDetails();
        }else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },
    //取消
    orderReceivingCancel1(){
      this.orderReceivingisEdit1 = false;
      this.getDetails();
    },

    //点击订单结算信息编辑
    sijieditedit(){
      this.sijiedit = true;
    },
    //确认
    sijieditqueding(){
      this.sijiedit = false;
    },
    //取消
    sijieditquxiao(){
      this.sijiedit = false;
    },

    //收款信息编辑
    shoukuaneditbianji(){
      console.log('点击收款信息的编辑')
      this.shoukuanedit = true;
    },

    //结算信息的编辑
    settlement(){
      this.settlementEdit = true;
    },
    //确认
    settlementConfirm(){
      this.settlementEdit = false;
    },
    //取消
    settlementCancel(){
      this.settlementEdit = false;
    },

    //报备
    Change(item,index){
      this.isShow1 = index;
      this.technician_report = item;
    },
    //驳回
    bohui(){
      this.showreasonForRejectionOfReport = true;
      this.reasonForRejectionOfReportcurrent = this.technician_report;
      this.id = this.data.id
    },
    //通过
    pass(){
      let data = {
        indexes: this.technician_report.indexes,
        status: '2'
      }
      // 调用修改抢单接口
      Savereport_audit(this.data.id,data).then(res => {
        if(res.code == 200){
          this.$message.success(res.msg);
          this.getDetails();
        }else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },

    //添加回访
    huifang(){
      this.showreturnVisit = true;
    },

    //催单
    cuidan(){
      this.showreminder = true;
      this.currentreminder = this.data;
    },

    //投诉
    Tousu(){
      this.currentcomplaint = this.data
      this.showcomplaint = true;
    },

    //标记
    biaoji(){
      this.dialogVisible = true;
    },
    handleClose(done) {
      this.dialogVisible = false;
      this.selectaddress = false;
    },
    //外协
    waixie(){
      this.showcoordination = true;
    },


    // 腾讯地图选址组件
    //点击选择地址
    opensiteselection(number){
      // 用于判断是起点还是终点
      this.origin = number;

      // 判断起点还是终点 0是起点 1是终点
      if(number == 0){
        this.mark = this.form.basics.starting_point_coord;
      }else {
        this.mark = this.form.basics.destination_coord;
      }

      this.selectaddress = true;
      //this.showAMapLoadersiteselection = true;
      this.showAMapLoadersiteselection = false;
      this.$nextTick(() => {
        this.showAMapLoadersiteselection = true;
      })

      //打开腾讯地图选址组件
      // let keys = setting.Tencentkey;
      // this.keyUrl = `https://apis.map.qq.com/tools/locpicker?type=1&key=${keys}&referer=myapp&coordtype=1&sig=ytLgIfdiuGLdxRAl1MZ7qwxPOI3W5K9k`;
    },
    // 选择经纬度
    selectAdderss(data) {
      console.log(data)
      // 救援起点
      if(this.origin == 0){
        // 救援起点的位置
        this.form.basics.starting_point = data.poiaddress + data.poiname;
        // 隐藏选址弹窗组件
        this.selectaddress = false;
        this.lazyLoading = !this.lazyLoading;
      }else {
        //救援终点位置
        this.form.basics.destination = data.poiaddress + data.poiname;
        // 隐藏选址弹窗组件
        this.selectaddress = false;
        this.lazyLoading = !this.lazyLoading;
      }
    },

    //获取选择地址后的数据
    getlocation(value){
      console.log(value)
      // 救援起点
      if(this.origin == 0){
        // 救援起点的位置
        this.form.basics.starting_point = value.pname + value.cityname + value.adname + value.address;
        // 救援起点的经度纬度
        this.form.basics.starting_point_coord[0] = value.location.lng;
        this.form.basics.starting_point_coord[1] = value.location.lat;
        // 隐藏选址弹窗组件
        // this.dialogVisible = false;
        // 赋值给传递的起点经度纬度
        this.locationorigin[0] = value.location.lng;
        this.locationorigin[1] = value.location.lat;
        // 调用重新渲染组件的方法
        this.reloadComponent();
        // this.lazyLoading = !this.lazyLoading;
      }else {
        //救援终点位置
        this.form.basics.destination = value.pname + value.cityname + value.adname + value.address;
        // 救援终点经度纬度
        this.form.basics.destination_coord[0] = value.location.lng;
        this.form.basics.destination_coord[1] = value.location.lat;
        // 隐藏选址弹窗组件
        // this.dialogVisible = false;
        // 赋值给传递的终点经度纬度
        this.locationdestination[0] = value.location.lng;
        this.locationdestination[1] = value.location.lat;
        // 调用重新渲染组件的方法
        this.reloadComponent();
        // this.lazyLoading = !this.lazyLoading;
      }
      this.selectaddress = false;
    },
    // 重新渲染组件
    reloadComponent() {
      this.currentComponent = null // 先把当前组件置为 null
      this.$nextTick(() => {
        this.currentComponent = AMapLoader // 然后再把要加载的组件赋值给 currentComponent
      })
    },

    // 接受创建订单子组件传递的值
    ShowOrder(value){
      console.log(value);
      // 隐藏子组件
      this.showEdit = value;
      this.getDetails()
    },

    //接收关联订单传递的值
    showadd(value){
      console.log(value)
      this.showEdit = value;
      this.content = this.data;
    },
    showname(value){
      this.orderName = value;
      this.content = this.data;
    },
    renovation(value){
      this.getDetails();
    },
    huoqugetDetails(){
      console.log('刷新')
      this.getDetails();
    },

    // 获取电话组件传递的值
    queryshow(value){
      // console.log(value)
      this.showPhone = value;
    },
    // 点击拨打电话
    tel(phone,tel_type){
      get_tel_no().then(res => {
        if(res.data.is_exist == 0){

          // 显示拨打电话弹窗
          let data = {
            showPhone: true
          }
          this.$addStorageEvent(1, "showPhone", JSON.stringify(data))
          // 要拨打的手机号
          let dianhua = {
            phone: phone
          }
          this.$addStorageEvent(1, "phone", JSON.stringify(dianhua))

          // 获取 订单号id
          let orderid = {
            orderid: this.data.id,
          }
          this.$addStorageEvent(1, "orderid", JSON.stringify(orderid))

          // 获取呼出类型
          let teltype = {
            tel_type: tel_type
          }
          this.$addStorageEvent(1, "tel_type", JSON.stringify(teltype))
        }else {
          this.$message.error('暂无坐席')
        }
      })
    },

    //点击抢单按钮
    qiangdan(){
      this.showqiangdan = true;
    },
    // 关闭抢单弹窗
    handleCloseshowqiangdan(){
      this.showqiangdan = false;
      this.price = '';
    },
    // 抢单确定
    qiangdansave(){
      if(this.price == ''){
        this.$message.error('请输入抢单价格')
      }else {
        let data = {
          odd: this.data.order_id,
          price: this.price
        }
        order_rob(this.data.id,data).then(res => {
          if(res.code == 200){
            this.$message.success(res.msg)
            this.showqiangdan = false;
            this.price = '';
            this.getDetails();
          }else {
            this.$message.error(res.msg)
          }
        })
      }
    },

    // 照片审核组件传递的数据
    queryshenhe(value){
      console.log(value);
      this.getDetails();
      this.PhotoReviewInformation = false;
      this.$nextTick(() => {
        this.PhotoReviewInformation = true;
      })
    },

    //点击取消按钮
    opencancel(){
      this.showcancel = true;
      this.canceldata = this.data;
    },
    //取消组件传递的事件
    getfanhui(value){
      this.$emit('showDetalis',false) ;
    },

    //点击空驶
    openempty(){
      this.showempty = true;
      this.emptydata = this.data;
    },

    //点击工单照片
    openshowworkorder(value){
      this.workorder = value;
      this.showworkorder = true;
    },
    // 关闭工单照片
    handleCloseworkorder(){
      this.showworkorder = false;
    },

  },
  // 生命周期 - 更新之前
  beforeUpdate() {
  },
  // 生命周期 - 更新之后
  updated() {
  },
  // 过滤器
  filters: {},
  // 生命周期 - 创建之前
  beforeCreate() {
  },
  // 生命周期 - 挂载之前
  beforeMount() {
  },
  // 生命周期 - 销毁之前
  beforeDestroy() {
  },
  // 生命周期 - 销毁完成
  destroyed() {
  },
  // 如果页面有 keep-alive 缓存功能,这个函数会触发
  // 进入的时候触发
  activated() {
  },
  // 离开的时候触发
  deactivated() {
  }
}
</script>

<style scoped lang="scss">
// 引入css
@import './style.scss';
.xian{
  width: 1px;
  height: 100px;
  border: 3px dashed #ff9b05;
  margin: auto;
}

.gongdanzhaop{
  width: 100%;
  text-align: center;
  img{
    width: 100%;
    height: 240px;
  }
  div{
    font-size: 16px;
    font-weight: 400;
    color: #050505;
    margin-top: 15px;
  }
}

//选择地点样式
.xuanzedidianyangshi{
  border-radius: 5px;
  -webkit-appearance: none;
  border: 1px solid var(--border-color-base);
  box-sizing: border-box;
  color: var(--color-text-regular);
  font-size: inherit;
  height: 36px;
  outline: none;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .didian{
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow:ellipsis;
  }
}
.ageing_b_text_r{
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
}
</style>

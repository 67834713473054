<!-- 备注回复弹窗 -->
<template>
  <el-dialog
      width="40%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'回复':'回复'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        label-width="100px">
      <el-row>
        <el-input
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 5}"
            placeholder="请输入内容"
            v-model="reply">
        </el-input>
      </el-row>


    </el-form>


    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">确定
      </el-button>
    </div>

  </el-dialog>
</template>

<script>

import {reminder_reply} from '@/api/orderModule'

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    item: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      reply:'',
    };
  },

  mounted() {
    if (this.data) {
      console.log(this.data)
      this.form = Object.assign({}, this.data);
      this.isUpdate = true;
    } else {
      this.form = {};
      this.isUpdate = false;
    }
  },

  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let data = {
            odd: this.data.order_id,
            reply: this.reply,
          }
          reminder_reply(this.item.id,data).then(res => {
            console.log(res)
            if (res.code === 200) {
              this.loading = true;
              this.updateVisible(false);
              this.$emit('done');
              this.loading = false;
              this.reply = '';
              this.$message.success(res.msg)
            }else {
              this.$message.error(res.msg)
            }
          })

        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },



  }
}
</script>

<style scoped lang="scss">

</style>

<template>
  <!--照片审核信息-->
  <div>
    <!--通话信息-->
    <div class="ageing" style="margin-top: 20px;">
      <div class="Datalist">
        <div class="Datalist_l">
          <div class="Datalist_icon"></div>
          <div class="Datalist_text">
            照片审核信息
          </div>
        </div>
      </div>

      <div>
        <!--标题模块-->
        <div style="margin-top: 10px;">
          <el-row>
            <span style="font-size: 18px;font-weight: 700;text-align: right;color: #333333;">{{type}}</span>
            <!--<span style="font-size: 14px;font-weight: 500;text-align: right;color: #333333;margin-left: 10px;">(照片数量不少于5张)</span>-->
          </el-row>
          <div>
            救援师傅与车合影
          </div>
          <div style="display:flex;">
            <!--car_owner_photo-->
            <el-upload
              :action="action"
              :headers="headers"
              :on-success="successUpload2"
              :limit="1"
              :file-list="fileList1"
              :class="{hide_box: upload_btn}"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview1"
              :on-remove="handleRemove1">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible1">
              <img width="100%" :src="dialogImageUrl1" alt="">
            </el-dialog>
            <div v-if="kilometerInformation.field_calibration == 0" style="color: #36DD9E;margin-top: 40px;">
              {{kilometerInformation.field_calibration_msg}}
            </div>
            <div v-else style="color: red;margin-top: 40px;">
              {{kilometerInformation.field_calibration_msg}}
            </div>
          </div>
          <div v-if="oddevenaddress == 'double'" style="margin-top: 20px;">
            门头照与救援车合影
            <!--door_car_photo-->
            <el-upload
              :action="action"
              :headers="headers"
              :on-success="successUpload3"
              :limit="1"
              :file-list="fileList2"
              :class="{hide_box: upload_btn1}"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview2"
              :on-remove="handleRemove2">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible2">
              <img width="100%" :src="dialogImageUrl2" alt="">
            </el-dialog>
          </div>
        </div>
        <!--边框里的内容-->
        <div class="ageing_b" style="padding: 20px;padding-top: 10px;" v-for="(item,index) in List" :key="index">
          <!--每一项-->
          <div>
            <el-row :gutter="15">
              <!--左-->
              <el-col :span="12">
                <!--<div style="font-size: 18px;font-weight: 700;color: #050505;">2.1 到达现场：</div>-->
                <div style="font-size: 16px;font-weight: 700;color: #050505;">{{item.sample_graph_name}}</div>
                <div style="font-size: 16px;font-weight: 400;color: #6c6c6c;margin-top: 10px;">{{item.claim}}</div>
                <div style="font-size: 14px;font-weight: 400;color: #909090;margin-top: 20px;">示例图：</div>
                <div style="width: 240px;height: 180px;background-size: 100% 100%; background-repeat: no-repeat;margin-top: 5px;" :style="{ backgroundImage: `url(${item.sample_graph_path})` }">
                </div>
              </el-col>
              <!-- 右 -->
              <el-col :span="12">
                <div style="font-size: 14px;font-weight: 400;color: #050505;">实拍图：</div>
                <div style="background: #fafafa;border: 1px solid #e9eaf2;padding: 20px;margin-top: 20px;display: flex;flex-wrap: wrap;">
                  <div v-for="(it,idx) in item.photo_url" :key="idx" style="margin-right: 20px;margin-bottom: 20px;">
                    <div style="width: 148px;height: 148px;" class="proBK">
                      <img :src="it.url" style="width: 100%;height: 100%;">
                      <div class="imgText">
                        <!--查看图片-->
                        <i class="el-icon-view" @click="proview(it,idx,index)"></i>
                        <!--修改图片-->
                        <el-upload
                          class="upload-demo"
                          ref="upload"
                          :action="action"
                          :headers="headers"
                          :show-file-list="false"
                          :on-success="successUpload1"
                          :auto-upload="true">
                          <i slot="trigger" class="el-icon-edit" @click="anew(it,idx,item)"></i>
                        </el-upload>
                      </div>
                    </div>
                    <div v-if="it.is_qualified == -1" style="background: #F56C6C;color: #fff;text-align: center;cursor: pointer;padding-top: 5px;padding-bottom: 5px;">
                      <!-- -1不通过 1通过 0审核中 -->
                      <el-upload
                        class="upload-demo"
                        ref="upload"
                        :action="action"
                        :headers="headers"
                        :show-file-list="false"
                        :on-success="successUpload1"
                        :auto-upload="true">
                        <span slot="trigger" @click="anew(it,idx,item)">重新上传</span>
                      </el-upload>
                    </div>
                    <div v-if="it.is_qualified == 1" style="background: #67C23A;color: #fff;text-align: center;cursor: pointer;padding-top: 5px;padding-bottom: 5px;">
                      <!-- -1不通过 1通过 0审核中 -->
                      <span>通过</span>
                    </div>
                    <div v-if="it.is_qualified == 0" style="background: #E6A23C;color: #fff;text-align: center;cursor: pointer;padding-top: 5px;padding-bottom: 5px;">
                      <!-- -1不通过 1通过 0审核中 -->
                      <span>审核中</span>
                    </div>
                  </div>
                  <div @click="xuanze(item,index)">
                    <el-upload
                      list-type="picture-card"
                      :on-preview="handlePictureCardPreview"
                      :action="action"
                      :headers="headers"
                      :show-file-list="false"
                      :on-success="successUpload"
                      :file-list="item.photo_url"
                      :on-remove="handleRemove">
                      <i class="el-icon-plus"></i>
                    </el-upload>
                  </div>
                  <el-dialog :visible.sync="dialogVisible" title="照片审核详情" center>
                    <el-row :gutter="15">
                      <el-col :span="12">
                        <img width="100%" :src="info.url" alt="">
                      </el-col>
                      <el-col :span="12">
                        <div style="display: flex;align-items: center;">
                          <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;"></span>
                          <span style="font-size: 18px;font-weight: 700;color: #3f4155;margin-left: 10px;">{{info.sample_graph_name}}</span>
                        </div>
                        <div style="width: 100%; height: 200px; border-radius: 6px; margin-top: 10px;">
                          <img :src="info.sample_graph_path" alt="" style="width: 100%;height: 100%;">
                        </div>
                        <div style="margin-top: 10px;font-size: 14px;font-weight: 400;">
                          <div class="bianju">
                            <span style="color: #868792;">照片要求：</span>
                            <span>{{info.claim}}</span>
                          </div>
                          <div class="bianju">
                            <span style="color: #868792;">服务项目：</span>
                            <span>{{info.items_name}}</span>
                          </div>
                          <div class="bianju">
                            <span style="color: #868792;">车牌号码：</span>
                            <span>{{info.license_plate}}</span>
                          </div>
                          <div class="bianju">
                            <span style="color: #868792;">车架号码：</span>
                            <span>{{info.vin}}</span>
                          </div>
                          <div class="bianju">
                            <span style="color: #868792;">客户信息：</span>
                            <span>{{info.owners}} {{info.owners_phone}}</span>
                          </div>
                          <div class="bianju">
                            <span style="color: #868792;">上传信息：</span>
                            <span>{{info.update_info}}</span>
                          </div>
                          <div class="bianju">
                            <span style="color: #868792;">上传时间：</span>
                            <span>{{info.update_time}}</span>
                          </div>
                          <div class="bianju">
                            <span style="color: #868792;">审核状态：</span>
                            <span style="color: #FF9B05; ">{{info.is_qualified}}</span>
                          </div>
                          <div class="bianju" v-if="info.is_qualified == '不合格'">
                            <span style="color: #868792;">审核不合格原因：</span>
                            <span style="color: #FF9B05; ">{{info.fail_reason}}</span>
                          </div>

                        </div>

                        <!--审核按钮-->
                        <div style="margin-top: 20px;display: flex;justify-content: space-between;" v-if="info.is_qualified != '不合格' && info.is_qualified != '合格'">
                          <el-button style="background: #36DD9E!important;border-color: #36DD9E!important;color: #FFFFFF!important;" @click="qualified">合格</el-button>
                          <!--<el-button style="background: #36DD9E!important;border-color: #36DD9E!important;color: #FFFFFF!important;" @click="qualifiedbelow">合格，下一张</el-button>-->
                          <el-button style="background: #F54E71!important;border-color: #F54E71!important;color: #FFFFFF!important;" @click="showfail_reason = true">不合格</el-button>
                        </div>
                        <!--<div style="display: flex;justify-content: space-between;margin-top: 15px;" v-if="info.is_qualified != '不合格' && info.is_qualified != '合格'">-->
                        <!--  <el-button style="background: #F54E71!important;border-color: #F54E71!important;color: #FFFFFF!important;" @click="showfail_reason = true">不合格</el-button>-->
                        <!--</div>-->
                        <div style="margin-top: 15px;" v-if="showfail_reason == true">
                          <div>
                            <el-input
                              placeholder="请输入不通过理由"
                              v-model="fail_reason"
                              clearable>
                            </el-input>
                          </div>
                          <div style="margin-top: 10px;text-align: center;">
                            <el-button type="primary" @click="queding">确定</el-button>
                            <el-button @click="quxiao">取消</el-button>
                          </div>

                        </div>
                        <div style="margin-top: 20px;display: flex;align-items: center;justify-content: space-between;flex-direction: row;flex-wrap: nowrap;">
                          <!--<el-button style="background: #f1f1f1 !important;border-color: #dcdce0!important;">-->
                          <!--  <i class="el-icon-leftjiantou" />-->
                          <!--  <span style="vertical-align: middle">上一张</span>-->
                          <!--</el-button>-->
                          <el-button type="primary" plain @click="up()">上一张</el-button>
                          <span>{{idx}}/{{item.photo_url.length}}</span>
                          <el-button type="primary" plain @click="below()">下一张</el-button>
                          <!--<el-button style="background: #f1f1f1 !important;border-color: #dcdce0!important;">-->
                          <!--  <i class="el-icon-rightjiantou" />-->
                          <!--  <span style="vertical-align: middle">下一张</span>-->
                          <!--</el-button>-->
                        </div>

                      </el-col>
                    </el-row>
                  </el-dialog>

                </div>
              </el-col>

            </el-row>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
  // 获取配置
  import setting from '@/config/setting'
  import {getCookies} from '@/utils/cookies'
  import Config from '@/config'

  // 引入的接口
  import {get_picture, picture_flunk, picture_pass, update_picture,update_group_photo} from "@/api/orderModule";

  export default {
    props:{
      data: Array,
      type: String,
      fatherdata: Object,
      odd_even_address: String,
      basics: Object,
      kilometerInformation: Object
    },
    data(){
      return{
        // 上传接口
        action: setting.apiBaseURL + 'common/upload/order',
        // 上传请求头部
        headers:{
          'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
        },
        List:[],

        //示例图
        img:require('../../../assets/images/home/order/shilitu.png'),
        dialogImageUrl: '',
        dialogVisible: false,
        fileList:[],

        // 照片详情数据
        info:{},
        // 当前查看的图片下标 用于展示
        idx:0,
        // 真是下标
        index: 0,
        // 父级下标
        father:0,
        // 上一张的数据
        updata:{},
        // 下一张的数据
        belowdata:{},

        //不通过理由
        fail_reason:'',
        // 不通过模块
        showfail_reason:false,

        // 顶级父级数据
        fujiData:{},

        // 重新上传数据
        anewuploaddata:{
          odd:'',
          uniques: '',
          url: '',
          indexes: '',
        },

        //单双地址
        oddevenaddress:'',

        //图片
        Basics:{},


        // 救援师傅与车合影
        fileList1:[],
        upload_btn: false,
        dialogImageUrl1: '',
        dialogVisible1: false,

        // 门头照与救援车合影
        fileList2:[],
        upload_btn1: false,
        dialogImageUrl2: '',
        dialogVisible2: false,

      }
    },

    mounted() {
      // 单双地址
      this.oddevenaddress = this.odd_even_address;
      console.log(this.oddevenaddress)

      // 图片
      this.Basics = this.basics;
      // 救援师傅与车合影
      if(this.Basics.car_owner_photo != ''){
        let data = {
          url: this.Basics.car_owner_photo,
        };
        this.upload_btn = true;
        this.fileList1.push(data);
      }

      //门头照与救援车合影
      if(this.Basics.door_car_photo != ''){
        let data = {
          url: this.Basics.door_car_photo,
        };
        this.upload_btn1 = true;
        this.fileList2.push(data);
      }


      console.log(this.Basics,'图片')


      this.List = this.data;
      console.log(this.List,'数据')

      console.log(this.fatherdata,'父级数据')

      console.log('公里信息',this.kilometerInformation)

    },

    methods:{
      // 删除图片 暂时不起作用
      handleRemove(file, fileList) {
        console.log(file, fileList);
      },
      // 查看图片 暂时不起作用
      handlePictureCardPreview(file,fileList) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
        // 当前查看的图片信息
        // console.log(file)
        // // 调用获取订单照片信息接口
        // let params = {
        //   indexes: file.indexes
        // }
        // get_picture(this.fatherdata.id,params).then(res => {
        //   console.log(res)
        //   if(res.code == 200){
        //     this.info = res.data
        //   }else {
        //     this.$message.error(res.msg)
        //   }
        // })
      },
      // 当前选择的父级数据
      xuanze(item,index){
        console.log(item)
        console.log(index)
        this.fujiData = item;
      },
      // 上传成功
      successUpload(file,fileList){
        console.log(file)
        // 新增订单照片接口
        let data = {
          odd: this.fatherdata.order_id,
          uniques: this.fujiData.uniques,
          url: file.data.urls
        }
        update_picture(this.fatherdata.id,data).then(res => {
          console.log(res)
          if(res.code == 200){
            this.$message.success(res.msg);
            this.dialogVisible = false;
            this.$emit('shenhe',true)
          }else {
            this.$message.error(res.msg);
          }
        })
      },

      // 点击重新上传
      anew(it,idx,item){
        console.log(it)
        console.log(item)
        //重新上传数据
        this.anewuploaddata.odd = this.fatherdata.order_id;
        this.anewuploaddata.uniques = it.sample_uniques;
        this.anewuploaddata.indexes = it.indexes;
      },
      // 重新上传成功
      successUpload1(file,fileList){
        console.log(file)
        this.anewuploaddata.url = file.data.urls;
        update_picture(this.fatherdata.id,this.anewuploaddata).then(res => {
          console.log(res)
          if(res.code == 200){
            this.$message.success(res.msg);
            // 清空下数据
            this.anewuploaddata.odd = '';
            this.anewuploaddata.uniques = '';
            this.anewuploaddata.indexes = '';
            this.anewuploaddata.url = '';
            this.dialogVisible = false;
            this.$emit('shenhe',true)
          }else {
            this.$message.error(res.msg);
          }
        })
      },


      // 点击查看
      proview(it,idx,index){
        // 当前查看的图片信息
        console.log(it)
        console.log(idx)
        // 调用获取订单照片信息接口
        let params = {
          indexes: it.indexes
        }
        get_picture(this.fatherdata.id,params).then(res => {
          console.log(res)
          if(res.code == 200){
            this.idx = idx + 1;
            this.index = idx;
            console.log('index',this.index)
            this.father = index;
            this.dialogImageUrl = it.url;
            this.dialogVisible = true;
            this.info = res.data
          }else {
            this.$message.error(res.msg)
          }
        })
      },

      // 点击上一张
      up(){
        console.log('上一张');
        if(this.idx == 1){
          this.$message.error('已经是第一张了')
        }else {
          // let index = this.index;
          // console.log('未修改前的下标',index)
          // if(this.index != 0){
          //   index = index - 1
          //   console.log('123465')
          // }else {
          //   index = this.index
          //   console.log('asdasd')
          // }
          console.log(this.index,'下标')
          let index = this.index - 1;
          let dangqian = this.List[this.father].photo_url[index]
          console.log(dangqian)
          //调用获取订单照片信息接口
          let params = {
            indexes: dangqian.indexes
          }
          get_picture(this.fatherdata.id,params).then(res => {
            console.log(res)
            if(res.code == 200){
              this.idx = this.idx - 1;
              this.index = index;
              this.info = res.data
            }else {
              this.$message.error(res.msg)
            }
          })
        }
      },
      // 点击下一张
      below(){
        console.log('下一张')
        console.log(this.List[this.father].photo_url.length)
        if(this.idx == this.List[this.father].photo_url.length){
          this.$message.error('已经最后一张了')
        }else {
          let dangqian = this.List[this.father].photo_url[this.idx]
          console.log(dangqian)
          // 调用获取订单照片信息接口
          let params = {
            indexes: dangqian.indexes
          }
          get_picture(this.fatherdata.id,params).then(res => {
            console.log(res)
            if(res.code == 200){
              this.idx = this.idx + 1;
              this.info = res.data
            }else {
              this.$message.error(res.msg)
            }
          })
        }
      },

      // 审核不通过按钮操作
      queding(){
        // this.info.indexes
        let data = {
          indexes: this.info.indexes,
          fail_reason: this.fail_reason
        }
        picture_flunk(this.fatherdata.id,data).then(res => {
          console.log(res)
          if(res.code == 200){
            this.$message.success(res.msg);
            // 隐藏不通过理由模块
            this.showfail_reason = false;
            // 清空数据
            this.fail_reason = '';
            this.dialogVisible = false;
            this.$emit('shenhe',true)
          }else {
            this.$message.error(res.msg)
          }
        })
      },
      quxiao(){
        // 隐藏不通过理由模块
        this.showfail_reason = false;
        // 清空数据
        this.fail_reason = '';
      },

      // 点击合格
      qualified(){
        let params = {
          indexes: this.info.indexes,
        }
        picture_pass(this.fatherdata.id,params).then(res => {
          console.log(res)
          if(res.code == 200){
            this.$message.success(res.msg);
            this.dialogVisible = false;
            this.$emit('shenhe',true)
          }else {
            this.$message.error(res.msg)
          }
        })
      },

      // 合格下一张
      qualifiedbelow(){
        // 合格
        let params = {
          indexes: this.info.indexes,
        }
        picture_pass(this.fatherdata.id,params).then(res => {
          console.log(res)
          if(res.code == 200){
            this.$message.success(res.msg);
            // this.$emit('shenhe',true)
            // 合格后的下一张
            if(this.idx == this.List[this.father].photo_url.length){
              this.$message.error('已经最后一张了')
            }else {
              let dangqian = this.List[this.father].photo_url[this.idx]
              console.log(dangqian)
              // 调用获取订单照片信息接口
              let params = {
                indexes: dangqian.indexes
              }
              get_picture(this.fatherdata.id,params).then(res => {
                console.log(res)
                if(res.code == 200){
                  this.idx = this.idx + 1;
                  this.info = res.data
                }else {
                  this.$message.error(res.msg)
                }
              })
            }
          }else {
            this.$message.error(res.msg)
          }
        })
      },


      // 单独的上传图片
      // 救援师傅与车合影
      // 删除图片
      handleRemove1(file, fileList) {
        console.log(file, fileList);
        this.upload_btn = false;
      },
      // 查看图片
      handlePictureCardPreview1(file,fileList) {
        this.dialogImageUrl1 = file.url;
        this.dialogVisible1 = true;
      },
      successUpload2(file){
        console.log(file)
        this.upload_btn = true;
        let data = {
          odd: this.fatherdata.order_id,
          car_owner_photo: file.data.urls
        }
        update_group_photo(this.fatherdata.id,data).then(res => {
          console.log(res)
          if(res.code == 200){
            this.$message.success(res.msg);
            this.$emit('shenhe',true)
          }else {
            this.$message.error(res.msg)
          }
        })
      },


      // 门头照与救援车合影
      handleRemove2(file, fileList) {
        console.log(file, fileList);
        this.upload_btn1 = false;
      },
      // 查看图片
      handlePictureCardPreview2(file,fileList) {
        this.dialogImageUrl2 = file.url;
        this.dialogVisible2 = true;
      },
      successUpload3(file){
        console.log(file)
        this.upload_btn = true;
        let data = {
          odd: this.fatherdata.order_id,
          door_car_photo: file.data.urls
        }
        update_group_photo(this.fatherdata.id,data).then(res => {
          console.log(res)
          if(res.code == 200){
            this.$message.success(res.msg);
            this.$emit('shenhe',true)
          }else {
            this.$message.error(res.msg)
          }
        })
      },


    }

  }
</script>

<style lang="scss" scoped>
  .bianju{
    margin-bottom: 10px;
  }

  .proBK {
    position: relative;
  }
  .proImg {
    width: 100%;
    height: 100%;
  }
  .imgText{
    position: absolute;
    background: rgba(000, 000, 000, 0.5);
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  dangqian {
    font-size: 18px;
  }
  .proBK .imgText:hover {
    opacity: 1;
  }


  .hide_box /deep/ .el-upload--picture-card {
    display: none;
  }
</style>

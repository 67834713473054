<template>
  <!--对账信息模块-->
  <div>
    <el-card shadow="never">
      <!-- 应收结算 -->
      <div class="ageing">
        <div class="Datalist">
          <div class="Datalist_l">
            <div class="Datalist_icon"></div>
            <div class="Datalist_text">
              客户应收结算
              <el-button class="zuobianju" type="primary" size="small" @click="edit">修改</el-button>
            </div>
            <div class="custom" style="margin-left: 20px">
              <span style="color: #5976E1; font-size: 20px;font-weight: 700;">（{{info.client_info.channel_reconciliation_msg}}）</span>
            </div>
          </div>
        </div>
        <div class="ageing_b" style="padding: 20px;">
          <el-row :gutter="15">
            <el-col :span="19">
              <div style="display: flex;align-items: center;">
                <span class='duizhangxinistyle1'>应收款：</span>
                <span class='duizhangxinistyle'>{{info.client_info.client_price}}元</span>
                <!--<span style="font-size: 16px;font-weight: 400;color: #4e4e4e; margin-left: 30px;">合同金额：</span>-->
                <!--<span class='font2'>540</span>-->
              </div>
              <div class='beijingstyle'>
                <el-row :gutter="15">
                  <el-col :span="8">
                    <div class="xiang">
                      <span class="xiang_l">计费模式：</span>
                      <span class="xiang_r">{{info.client_info.price_msg}}</span>
                    </div>
                  </el-col>
                </el-row>

               <div v-if="info.client_info.odd_even_address == 'single'">
                 <div v-if="info.client_info.billing_model == 'mileage'">
                   <el-row :gutter="15">
                     <el-col :span="8">
                       <div class="xiang">
                         <span class="xiang_l">起步价({{info.client_info.starting_kilometre}}公里内)：</span>
                         <span class="xiang_r">{{info.client_info.starting_price}}元</span>
                       </div>
                     </el-col>
                     <el-col :span="8">
                       <div class="xiang">
                         <span class="xiang_l">超出里程费(超出{{info.client_info.exceed}}公里)：</span>
                         <span class="xiang_r">{{info.client_info.exceed_price}}元</span>
                       </div>
                     </el-col>
                   </el-row>
                 </div>
                 <div v-else>
                   <el-row :gutter="15">
                     <el-col :span="8">
                       <div class="xiang">
                         <span class="xiang_l">一口价：</span>
                         <span class="xiang_r">{{info.client_info.client_price}}元</span>
                       </div>
                     </el-col>
                   </el-row>
                 </div>
               </div>

                <div v-else>
                  <div v-if="info.client_info.billing_model == 'mileage'">
                    <el-row :gutter="15">
                      <el-col :span="8">
                        <div class="xiang">
                          <span class="xiang_l">（去程）起步价({{info.client_info.trip_starting_kilometre}}公里内)：</span>
                          <span class="xiang_r">{{info.client_info.trip_starting_price}}元</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="xiang">
                          <span class="xiang_l">超出里程费(超出{{info.client_info.trip_exceed_km}}公里)：</span>
                          <span class="xiang_r">{{info.client_info.trip_exceed_price}} 元</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="xiang">
                          <span class="xiang_l">（去程）超出里程单价(1公里)：{{info.client_info.trip_exceed}}元</span>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row :gutter="15">
                      <el-col :span="8">
                        <div class="xiang">
                          <span class="xiang_l">（背程）起步价({{info.client_info.journey_starting_kilometre}}公里内)：</span>
                          <span class="xiang_r">{{info.client_info.journey_starting_price}}元</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="xiang">
                          <span class="xiang_l">超出里程费(超出{{info.client_info.journey_exceed_km}}公里)：</span>
                          <span class="xiang_r">{{info.client_info.journey_exceed_price}}元</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="xiang">
                          <span class="xiang_l">（背程）超出里程单价(1公里)：{{info.client_info.journey_exceed}}元</span>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row :gutter="15">
                      <el-col :span="8">
                        <div class="xiang">
                          <span class="xiang_l">辅轮【单价 {{info.client_info.pulley_unit_price}} * 数量{{info.client_info.pulley_count}}】</span>
                          <span class="xiang_r">{{info.client_info.pulley_price}}元</span>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                  <div v-else>
                    <el-row :gutter="15">
                      <el-col :span="8">
                        <div class="xiang">
                          <span class="xiang_l">一口价：</span>
                          <span class="xiang_r">{{info.client_info.client_price}}元</span>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>

              </div>
            </el-col>
            <!--<el-col :span="5">-->
            <!--  <div>-->
            <!--    <div style="display: flex;align-items: center;">-->
            <!--      <span style="width: 5px;height: 16px;background: #a3a4b2; display: inline-block;"></span>-->
            <!--      <span style="font-size: 18px;font-weight: 500;color: #3f4155;margin-left: 15px;">应收结算</span>-->
            <!--    </div>-->
            <!--    <div style="margin-top: 15px;font-size: 14px;font-weight: 400;color: #3f4155;">-->
            <!--      合同金额明细：-->
            <!--    </div>-->
            <!--    <div style="font-size: 14px;font-weight: 400;text-align: left;color: #868792;margin-top: 5px;">-->
            <!--      1. 一口价的显示：一口价            议价的订单显示：议价    超出里程费用、地库、辅轮有就显示，没有不显示-->
            <!--    </div>-->
            <!--    <div style="font-size: 14px;font-weight: 400;text-align: left;color: #868792;">-->
            <!--      2. 有增补服务项的显示增补项目-->
            <!--    </div>-->
            <!--    <div style="font-size: 14px;font-weight: 400;text-align: left;color: #868792;">-->
            <!--      3. 客户金额修改过显示议价-->
            <!--    </div>-->
            <!--  </div>-->
            <!--</el-col>-->
          </el-row>
        </div>
      </div>

      <!-- 应收结算 -->
      <div class="ageing" style="margin-top: 30px;" v-if="Object.keys(info.facilitator_info).length != 0">
        <div class="Datalist">
          <div class="Datalist_l">
            <div class="Datalist_icon"></div>
            <div class="Datalist_text">
              服务商应收结算
            </div>
            <div class="custom" style="margin-left: 20px">
              <span style="color: #FF9B05; font-size: 20px;font-weight: 700;">（{{info.facilitator_info.facilitator_reconciliation_msg}}）</span>
            </div>
          </div>
        </div>
        <div class="ageing_b" style="padding: 20px;">
          <el-row :gutter="15">
            <el-col :span="19">
              <div style="display: flex;align-items: center;">
                <span class='duizhangxinistyle1'>应付款：</span>
                <span >{{info.facilitator_info.contract_price}}元</span>
                <!--<span style="font-size: 16px;font-weight: 400;color: #4e4e4e; margin-left: 30px;">合同金额：</span>-->
                <!--<span class='font2'>540</span>-->
              </div>
              <div class='beijingstyle'>
                <el-row :gutter="15">
                  <el-col :span="8">
                    <div class="xiang">
                      <span class="xiang_l">计费模式：</span>
                      <span class="xiang_r">{{info.facilitator_info.price_msg}}</span>
                    </div>
                  </el-col>
                </el-row>
                <div v-if="info.facilitator_info.odd_even_address == 'single'">
                  <div v-if="info.facilitator_info.billing_model == 'mileage'">
                    <el-row :gutter="15">
                      <el-col :span="8">
                        <div class="xiang">
                          <span class="xiang_l">起步价({{info.facilitator_info.starting_kilometre}}公里内)：</span>
                          <span class="xiang_r">{{info.facilitator_info.starting_price}}元</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="xiang">
                          <span class="xiang_l">超出里程费(超出{{info.facilitator_info.exceed}}公里)：</span>
                          <span class="xiang_r">{{info.facilitator_info.exceed_price}}元</span>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                  <div v-else>
                    <el-row :gutter="15">
                      <el-col :span="8">
                        <div class="xiang">
                          <span class="xiang_l">一口价：</span>
                          <span class="xiang_r">{{info.facilitator_info.contract_price}}元</span>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>

                <div v-else>
                  <div v-if="info.client_info.billing_model == 'mileage'">
                    <el-row :gutter="15">
                      <el-col :span="8">
                        <div class="xiang">
                          <span class="xiang_l">（去程）起步价({{info.facilitator_info.trip_starting_kilometre}}公里内)：</span>
                          <span class="xiang_r">{{info.facilitator_info.trip_starting_price}}元</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="xiang">
                          <span class="xiang_l">超出里程费(超出{{info.facilitator_info.trip_exceed_km}}公里)：</span>
                          <span class="xiang_r">{{info.facilitator_info.trip_exceed_price}} 元</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="xiang">
                          <span class="xiang_l">（去程）超出里程单价(1公里)：{{info.facilitator_info.trip_exceed}}元</span>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row :gutter="15">
                      <el-col :span="8">
                        <div class="xiang">
                          <span class="xiang_l">（背程）起步价({{info.facilitator_info.journey_starting_kilometre}}公里内)：</span>
                          <span class="xiang_r">{{info.facilitator_info.journey_starting_price}}元</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="xiang">
                          <span class="xiang_l">超出里程费(超出{{info.facilitator_info.journey_exceed_km}}公里)：</span>
                          <span class="xiang_r">{{info.facilitator_info.journey_exceed_price}}元</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="xiang">
                          <span class="xiang_l">（背程）超出里程单价(1公里)：{{info.facilitator_info.journey_exceed}}元</span>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row :gutter="15">
                      <el-col :span="8">
                        <div class="xiang">
                          <span class="xiang_l">辅轮【单价 {{info.facilitator_info.pulley_unit_price}} * 数量{{info.facilitator_info.pulley_count}}】</span>
                          <span class="xiang_r">{{info.facilitator_info.pulley_price}}元</span>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                  <div v-else>
                    <el-row :gutter="15">
                      <el-col :span="8">
                        <div class="xiang">
                          <span class="xiang_l">一口价：</span>
                          <span class="xiang_r">{{info.facilitator_info.contract_price}}元</span>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>

              <div>
                <div style="display: flex;align-items: center; margin-top: 20px;">
                  <span class='font1'>订单补扣款：</span>
                  <span class='font2'>-{{info.facilitator_info.repair_thread_price}}</span>
                </div>
                <div class='beijingstyle'>
                  <el-row :gutter="15">
                    <el-col :span="8" v-for="(item,index) in info.thread_info" :key="index">
                      <div class="xiang">
                        <span class="xiang_l">{{item.name}}({{item.type}})：</span>
                        <span class="xiang_r">{{item.figure}}</span>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>

            </el-col>
            <!--<el-col :span="5">-->
            <!--  <div>-->
            <!--    <div style="display: flex;align-items: center;">-->
            <!--      <span style="width: 5px;height: 16px;background: #a3a4b2; display: inline-block;"></span>-->
            <!--      <span style="font-size: 18px;font-weight: 500;color: #3f4155;margin-left: 15px;">应收结算</span>-->
            <!--    </div>-->
            <!--    <div style="margin-top: 15px;font-size: 14px;font-weight: 400;color: #3f4155;">-->
            <!--      合同金额明细：-->
            <!--    </div>-->
            <!--    <div style="font-size: 14px;font-weight: 400;text-align: left;color: #868792;margin-top: 5px;">-->
            <!--      1. 一口价的显示：一口价            议价的订单显示：议价    超出里程费用、地库、辅轮有就显示，没有不显示-->
            <!--    </div>-->
            <!--    <div style="font-size: 14px;font-weight: 400;text-align: left;color: #868792;">-->
            <!--      2. 有增补服务项的显示增补项目-->
            <!--    </div>-->
            <!--    <div style="font-size: 14px;font-weight: 400;text-align: left;color: #868792;">-->
            <!--      3. 客户金额修改过显示议价-->
            <!--    </div>-->
            <!--  </div>-->
            <!--</el-col>-->
          </el-row>
        </div>
      </div>
    </el-card>

    <el-dialog
        title="修改价格"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
      <div>
        <el-input
            placeholder="请输入内容"
            v-model="input"
            clearable>
        </el-input>
      </div>
      <div slot="footer">
        <el-button
            @click="dialogVisible = false">取消
        </el-button>
        <el-button
            style="margin-left: 20px;"
            type="primary"
            @click="dialogVisible = false">确定
        </el-button>
      </div>
    </el-dialog>

  </div>

</template>

<script>
  import {reconciliation_details} from '@/api/orderModule'

  export default {
    props: {
      //数据
      data: Object,
      //字典
      dict: Object
    },

    data(){
      return{
        info:{
          client_info: {
            channel_reconciliation_msg:'',
            client_price:'',
            price_msg:'',

          },
          facilitator_info:{},
          thread_info:[],
          supplement:[],
        },

        input:'',

        dialogVisible:false,
      }
    },

    mounted() {
      console.log(this.data)

      // this.getDetails();
    },

    methods:{
      // 获取订单对账详情接口
      getDetails(){
        let params = {
          odd: this.data.order_id
        }
        reconciliation_details(this.data.id, params).then(res => {
          console.log(res)
          this.info = res.data;
        })
      },

      edit(){
        this.dialogVisible = true;
        console.log(this.dialogVisible)
        this.$forceUpdate();
      },
      handleClose(){
        this.dialogVisible = false;
        this.$forceUpdate();
      },

    }

  }
</script>

<style lang="scss" scoped>
  .xiang{
    margin-top: 10px;
    margin-bottom: 10px;
    .xiang_l{
      //width: 180px;
      display: inline-block;
      text-align: left;
    }
    .xiang_r{
      display: inline-block;
    }
  }


  .duizhangxinistyle{
    font-size: 24px;font-weight: 700;color: #ff9b05; margin-left: 10px;
  }
  .duizhangxinxistyle1{
    font-size: 16px;font-weight: 400;color: #ff9b05;
  }
  .zuobianju{
    margin-left: 20px;
  }

  .beijingstyle{
    background: #f7f8fa;border-radius: 6px; padding: 20px;
    margin-top: 20px;
  }
</style>

<!-- 新增关联订单弹窗 -->
<template>
  <div>
    <!--<el-dialog-->
    <!--    width="40%"-->
    <!--    center-->
    <!--    :visible="visible"-->
    <!--    :lock-scroll="false"-->
    <!--    :destroy-on-close="true"-->
    <!--    custom-class="ele-dialog-form"-->
    <!--    :title="isUpdate?'关联订单':'关联订单'"-->
    <!--    @update:visible="updateVisible">-->
    <!--  <el-form-->
    <!--      ref="form"-->
    <!--      :model="form"-->
    <!--      label-width="100px">-->
    <!--    &lt;!&ndash; 选项 &ndash;&gt;-->
    <!--    <el-row>-->
    <!--      &lt;!&ndash;绑定背景图片 :style="{ backgroundImage: `url(${imgUrl})` }" &ndash;&gt;-->
    <!--      <div class="xuanxianglist">-->
    <!--        <div class="xuanxianglistactive" v-for="(item,index) in list" :key="index"  :class="{xuanxianglistactive1:index==isShow}" @click="activeItem(item,index)" :style="{ backgroundImage: `url(${item.img})` }" >-->
    <!--          <div style="line-height: 146px;padding-left: 15px;">-->
    <!--            <span>{{item.name}}</span>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </el-row>-->


    <!--  </el-form>-->

    <!--  &lt;!&ndash;<div slot="footer">&ndash;&gt;-->
    <!--  &lt;!&ndash;  <el-button&ndash;&gt;-->
    <!--  &lt;!&ndash;      @click="updateVisible(false)">取消&ndash;&gt;-->
    <!--  &lt;!&ndash;  </el-button>&ndash;&gt;-->
    <!--  &lt;!&ndash;  <el-button&ndash;&gt;-->
    <!--  &lt;!&ndash;      style="margin-left: 20px;"&ndash;&gt;-->
    <!--  &lt;!&ndash;      type="primary"&ndash;&gt;-->
    <!--  &lt;!&ndash;      :loading="loading"&ndash;&gt;-->
    <!--  &lt;!&ndash;      @click="save">确定&ndash;&gt;-->
    <!--  &lt;!&ndash;  </el-button>&ndash;&gt;-->
    <!--  &lt;!&ndash;</div>&ndash;&gt;-->
    <!--</el-dialog>-->

    <!--关联已有订单-->
    <el-dialog
        width="60%"
        center
        :visible="visible"
        :lock-scroll="false"
        :destroy-on-close="true"
        custom-class="ele-dialog-form"
        title='关联已有订单'
        @update:visible="updateVisible"
       >
          <!-- 搜索表单 -->
          <el-form
              :model="where"
              label-width="110px"
              class="ele-form-search">
              <el-row :gutter="15" style="display: flex; align-items: center;">
                <el-col :lg="6" :md="12">
                  <el-form-item label="订单号/报案号:">
                    <el-input
                        clearable
                        v-model="where.keyword"
                        placeholder="请输入订单号/报案号"/>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <el-form-item label="下单时间:">
                    <el-date-picker
                        style="width: 250px !important;"
                        v-model="where.order_time"
                        value-format="yyyy-MM-dd"
                        type="daterange"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :lg="12" :md="12">
                  <el-form-item label="">
                    <el-button class="custom-button" style="background: #3F4157 !important; border-color: #3F4157!important; color: #FFFFFF !important;" @click="QueryList">
                      <!--el-icon-devops 是自己命名的-->
                      <i class="el-icon-sousuo" />
                      <span style="vertical-align: middle">查询</span>
                    </el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>

            <div style="padding-bottom: 20px;">
              <!-- 数据表格 -->
              <my-table
                  ref="myTable"
                  :columns="columns"
                  :tableData="ListData"
                  :cellStyle="cellStyle"
                  :headerCellStyle="headerCellStyle"
                  :rowClassName="rowClassName"
                  :total="total"
                  :customsFromWhere="where"
                  :loading="loading1"
                  @select="select"
                  @ChangeSize="ChangeSize"
                  @currentChange="currentChange"
              >
                <!-- 不使用数据可不接收scope值 -->
                <template v-slot:operationSlot= "{ scope }">
                  <el-popconfirm title="确定执行此操作吗？" @confirm="relevance(scope.row)">
                    <el-link slot="reference" :underline="false" type="warning" v-auths="[`${$config.uniquePrefix}orderModule:info:relevanceorder`]">添加关联</el-link>
                  </el-popconfirm>
                </template>
              </my-table>
            </div>


    </el-dialog>

  </div>

</template>
<script>
// 引入的接口
import {getget_existing, Savecorrelation_existing} from "@/api/orderModule";
export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      //司机相关
      list:[
        {
          img: require('../../../../assets/images/home/orderbg.png'),
          name:'新建关联订单'
        },
        {
          img: require('../../../../assets/images/home/orderactivebg.png'),
          name:'关联已有订单'
        },
        // {
        //   img: require('../../../../assets/images/home/orderbg1.png'),
        //   name:'新增扣款处罚订单'
        // },
      ],
      isShow:1,

      haveOrder:false,

      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      ListData: [],
      // 总条目数
      total: 0,
      //加载动画
      loading1:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "订单号",
          // type: "index",
          // width: "70"
          prop: "order_id",
          isShow: true
        },
        {
          label: "报案号",
          prop: "case_number",
          isShow: true
        },
        {
          label: "故障车牌",
          prop: "license_plate",
          isShow: true
        },
        {
          label: "服务商",
          prop: "facilitator_name",
          isShow: true
        },
        {
          label: "服务人员",
          prop: "loss_assessor_name",
          isShow: true
        },
        {
          label: "服务人员手机号",
          prop: "loss_assessor_phone",
          isShow: true
        },

        {
          label: "操作",
          width: "200",
          slot: "operationSlot",
          isShow: true
        },
      ],
    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.updateVisible(false);
          this.$emit('done');
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //选择类型
    activeItem(item,index){
      console.log(item);
      console.log(index);
      this.isShow = index;
      if(item.name == '新建关联订单'){
        this.list[0].img = require('../../../../assets/images/home/orderactivebg1.png');
        this.list[1].img = require('../../../../assets/images/home/orderbg.png');
        // this.list[2].img = require('../../../../assets/images/home/orderbg.png');
        this.updateVisible(false);
        this.$emit('showAdd',true)
        this.$emit('showName','addpunish1')
      }else if(item.name == '关联已有订单'){
        this.list[0].img = require('../../../../assets/images/home/orderbg.png');
        this.list[1].img = require('../../../../assets/images/home/orderactivebg.png');
        // this.list[2].img = require('../../../../assets/images/home/orderbg.png');
        this.updateVisible(false);
        this.haveOrder = true;
        console.log(this.data);
      }else if(item.name == '新增扣款处罚订单'){
        this.list[0].img = require('../../../../assets/images/home/orderbg.png');
        this.list[1].img = require('../../../../assets/images/home/orderbg.png');
        this.list[2].img = require('../../../../assets/images/home/orderactivebg2.png');
        this.$emit('showAdd',true)
        this.$emit('showName','addpunish')
      }
    },

    handleClose(){
      this.haveOrder = false;
      this.updateVisible(true);
    },

    // 获取列表
    getList(){
      getget_existing(this.where).then(async (res) => {
        this.total = res.data.count;
        this.ListData = res.data.list;
        this.loading1 = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading1 = false;
      })
    },

    // 查询条件
    QueryList(){
      this.getList();
    },
    // 清空
    empty(){
      this.where = {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      }
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    //点击关联
    relevance(row){
      let where = {
        son_id: row.id
      }
      Savecorrelation_existing(this.data.id,where).then(res => {
        if(res.code == 200){
          this.$message.success(res.msg);
          this.haveOrder = false;
          this.updateVisible(false);
          this.$emit('shuaxin');
        }else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.msg);
      })
    },


  }
}
</script>

<style scoped lang="scss">
.xuanxianglist{

  .xuanxianglistactive{
    cursor: pointer;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 320px;
    height: 146px;
    font-size: 20px;
    font-weight: 500;
    color: #2d2f47;
    display: inline-block;
    margin: 10px;
  }
  .xuanxianglistactive1{
    color: #ff9b05;
  }

  //最后一个
  //.xuanxianglistactive:last-child{
  //  margin-right: 0;
  //}

}
</style>
